import React, {useEffect, useState} from "react";
import {
    dynomiteErrorPanel,
    getDaysFromDatePatterns,
    scrollHeight,
    shortenStr,
    sortShiftTypesByDaySegment
} from "../../lib/common";
import moment from "moment/moment";
import {dynoapeAPI} from "../../api/dynoape";
import {Button, Flex} from "rendition";
import Tooltip from "../../lib/tooltip";
import {WeekJumper} from "../../lib/styled-components";
import {PlannerWrapper, ReactGridWrapper} from "./employee-date-pattern-cases";
import {ReactGrid} from "@silevis/reactgrid";
import {CheckboxCellTemplate} from "../grid-components/checkbox-cell-template";
import {PatternCase} from "../../lib/pattern-case";
import {DropdownCellTemplate} from "../grid-components/dropdown-cell-template";
import {DropdownSelectCellTemplate} from "../grid-components/dropdown-select-cell-template";
import {DisabledInfoCellTemplate} from "../grid-components/disabled-info-cell-template";
import {holidayOptions, redDaysOptions} from "../../lib/options";
import "../grid-components/grid-styles.css";

export default ({
                    somePatternCaseIsDirty, setSomePatternCaseIsDirty, viewStartIndex, setViewStartIndex, setViewFlipSize, viewFlipSizeRef,
                    taskSelect, searchBar,
                    departmentId,
                    setSelectedTaskOption, selectedTask, setSelectedTask,
                    department, holidays, processAndSetHolidays, getVacationsForDaysAndEmployees, employees, setEmployees,
                    patternCase,
                    reset,
                    getReactGridRows, getReactGridHeaderRow,
                    showSavingModal, setShowSavingModal, saveModal,
                    dynomite, validate, configErrors, configWarnings, dynomiteInternalError,
                    startDate, setStartDate, nrOfWeeks, setNrOfWeeks, getRequirementsFromTask,
                    openedDropdownLocation, findAndSetOpenedDropdownCellLocation,
                    searchFilters, applySearchFiltersToEmployees, routerPrompt}) => {

    const [toSave, setToSave] = useState([]);
    const nrInView = 12;
    const [days, setDays] = useState(undefined)

    const [columns, setColumns] = useState(undefined);
    const [holidayRequirements, setHolidayRequirements] = useState(undefined);
    const [vacationsByEmployeeAndHoliday, setVacationsByEmployeeAndHoliday] = useState({});

    useEffect(() => {
        setDays(holidays.slice(0, nrInView))
        scrollHeight(2000);
        return () => {
            reset();
        }
    }, [])

    useEffect(() => {
        if(!days) return;
        if(!selectedTask) {
            setVacationsByEmployeeAndHoliday(getVacationsForDaysAndEmployees(days));
        }
    }, [days])

    useEffect(() => {
        if((somePatternCaseIsDirty && toSave.length > 0) || (!somePatternCaseIsDirty && toSave.length === 0)) return;
        setSomePatternCaseIsDirty(toSave.length > 0);
    }, [toSave])

    useEffect(() => {
        validate({employeesParam: applySearchFiltersToEmployees(employees).map(e => getLocalOrGlobalEmployee(e.id))});
    }, [toSave, employees, searchFilters, days])

    /**
     * Helper function for getting employee data. If there are unsaved employee data, we fetch it from local storage.
     * If not, the employee is returned
     * @param employeeId
     * @returns {*}
     */
    const getLocalOrGlobalEmployee = (employeeId) => {
        return toSave.find(e => e.id === employeeId) || employees.find(e => e.id === employeeId);
    }

    /**
     * Method for creating a valid holiday pattern based on either an existing or an empty one
     * @param pattern
     * @returns {{summary: string, includeDays: (*|*[]), optimize: boolean, series: null, kind: string, daySegments: string[], disabled: boolean, id: *, excludeDays: *[]}}
     */
    const validHolidayPattern = (pattern) => {
        return {
            id: pattern?.id,
            summary: "Helligdager på jobb",
            series: null,
            includeDays: pattern?.includeDays || [],
            excludeDays: [],
            daySegments: ["D", "A", "N", "L"],
            disabled: false,
            optimize: true,
            kind: "working"
        }
    }

    /**
     * UseEffect for creating the columns in the sheet. The followin columns exist:
     * - Row nr
     * - employee name
     * - employee position name
     * - daysegments which the employee can work
     * - employee.redDaysMaxSequence
     * - employee.movableHolidaysMaxPerTurnus
     * - one column for each holiday in the period
     * On the bottom, there are summations for both demand and covers each given day
     */
    useEffect(() => {
        if(!days) return;
        const cols =
            [
                { columnId: "nr", included: true, width: 40, text: "Rad", item: ({ idx }) => ({ type: "header", text: `${idx + 1}` }), demand: "", cover: "", diff: "", turnusWeek: "" },
                { columnId: "name", included: true, width: 265, text: "Navn", item: ({ employee }) => ({ type: "header", text: `${shortenStr(employee.name, 30)}` }), demand: "Bem.krav", cover: "Dekning", diff: "Differanse", turnusWeek: ""},
                { columnId: "position", included: true, width: 150, text: "Stilling", item: ({ employee }) => ({ type: "header", text: shortenStr(`${employee.restPosition?.name}`) }), demand: "", cover: "", diff: "", turnusWeek: ""},
                { columnId: "daySegment", included: true, width: 150, text: "Vaktkategorier", item: ({ employee }) => ({ type: "header", text: `${[...new Set(sortShiftTypesByDaySegment(employee.restShiftTypes)?.map(sh => sh.daySegment))]?.join(', ')}` }), demand: "", cover: "", diff: "", turnusWeek: ""},
                { columnId: "nrOfWorking", included: true, width: 130, text: "Antall helligdager", item: ({ employee }) => ({ type: "header", text: `${selectedTask ? resolveNrOfWorkingHolidays(employee.id) : ""}` }), demand: "", cover: "", diff: "", turnusWeek: "" },
                { columnId: "redDaysMaxSequence", included: true, width: 120, text: "Maks. antall røde dager på rad", item: ({ employee }) => ({ type: "dropdown-number", text: getLocalOrGlobalEmployee(employee.id).redDaysMaxSequence, values: redDaysOptions }), demand: "", cover: "", diff: "", turnusWeek: "" },
                { columnId: "movableHolidaysMaxPerTurnus", included: true, width: 180, text: "Maks. antall bevegelige høytidsdager", item: ({ employee }) => ({ type: "dropdown-number", text: getLocalOrGlobalEmployee(employee.id).movableHolidaysMaxPerTurnus, values: holidayOptions }), demand: "", cover: "", diff: "", turnusWeek: "" }
            ]
                .concat(days.map(d => {
                    const isOnVacation = (employeeId) => {
                        const formatted = moment(d).format('YYYY-MM-DD');
                        return vacationsByEmployeeAndHoliday[formatted] !== undefined
                            && vacationsByEmployeeAndHoliday[formatted][employeeId] === true
                    }
                    const isWeekend = [0, 6].includes(moment(d).day());
                    const worksWeekend = (employee) => {
                        if(!isWeekend || (employee[PatternCase.WEEKEND_PATTERN] ?? []).length === 0) return false;
                        if(isOnVacation(employee.id)) return false;
                        const sun = moment(d).endOf('isoWeek');
                        const working = getDaysFromDatePatterns(dynomite, employee[PatternCase.WEEKEND_PATTERN], sun, sun, ['working']);
                        const notWorking = getDaysFromDatePatterns(dynomite, employee[PatternCase.WEEKEND_PATTERN], sun, sun, ['not-working']);
                        return working.length > 0 && notWorking.length === 0;
                    }

                    const daySegmentFilters = searchFilters.daySegments === undefined || searchFilters.daySegments.length === 0
                        ? ["D", "A", "L", "N"]
                        : searchFilters.daySegments;

                    const _holRequirements = holidayRequirements !== undefined && holidayRequirements[d] !== undefined
                        ? daySegmentFilters.reduce((acc, ds) => {
                            acc.min += holidayRequirements[d][ds].min;
                            acc.max += holidayRequirements[d][ds].max;
                            return acc;
                        }, {min: 0, max: 0})
                        : undefined;

                    const demand = _holRequirements !== undefined ? _holRequirements.min : "";
                    const maxDemand = _holRequirements !== undefined ? _holRequirements.max : "";
                    const cover = applySearchFiltersToEmployees(employees, days[0], days[days.length -1]).reduce((count, e) => {return count + (worksHoliday(e.id, d) || worksWeekend(e) ? 1 : 0);}, 0) +"";
                    let diff = demand !== "" ? Number(cover) - Number(demand) : "";
                    diff = Number(cover) > Number(maxDemand) ? "+" + diff : diff;

                    const text = moment(d).format("DD-MM-YYYY") + " \n " + department.countryRules.holidays[d]
                    return { columnId: d, included: true, width: isWeekend ? 200 : 130, text: text,
                        item: ({ employee }) => (
                            isOnVacation(employee.id)
                                ? { type: 'empty-pink', text: `FE`, nonEditable: true}
                                : (isWeekend
                                    ? { type: 'empty', text: `${worksWeekend(employee) ? "Jobber" : ""}`, nonEditable: true }
                                    : { type: "checkbox", checked: worksHoliday(employee.id, d), checkedText: "Ja", uncheckedText: "Nei" })
                        ),
                        demand: demand,
                        cover: cover,
                        diff: diff,
                        turnusWeek: `Turnusuke \n${moment(d).diff(startDate, 'weeks') + 1}`
                    }}))
        setColumns(cols)
    }, [days, vacationsByEmployeeAndHoliday, employees, toSave, openedDropdownLocation, searchFilters])

    /**
     * Method for checking if an employee works a certain holiday
     * @param employeeId
     * @param holiday
     * @returns {boolean|boolean}
     */
    const worksHoliday = (employeeId, holiday) => {
        const employee = getLocalOrGlobalEmployee(employeeId);
        if(!employee.enabled || (employee.holidayPatterns ?? []).length === 0) return false;
        if(vacationsByEmployeeAndHoliday[holiday] !== undefined && vacationsByEmployeeAndHoliday[holiday][employeeId] === true) return false;
        return getDaysFromDatePatterns(dynomite, employee.holidayPatterns, holiday, holiday, ['working']).length > 0
    }

    /**
     * Methods for getting the total number of working holidays for an employee in a turnus period
     * @param employeeId
     * @returns {number}
     */
    const resolveNrOfWorkingHolidays = (employeeId) => {
        const employee = getLocalOrGlobalEmployee(employeeId);
        if(!employee.enabled) return 0;

        const endDate = moment(startDate).clone().add(nrOfWeeks, 'weeks').subtract(1, 'day');
        const allPatterns = [...employee.weekendPatterns, ...employee.holidayPatterns, ...employee.vacationPatterns];
        const notWorking = getDaysFromDatePatterns(dynomite, allPatterns, startDate, endDate, ['not-working', 'vacation'])
            .filter(d => days.includes(moment(d).format('YYYY-MM-DD')));

        const workingTmp = getDaysFromDatePatterns(dynomite, allPatterns, startDate, endDate, ['working']).filter(d => !notWorking.includes(d));
        let working = [...workingTmp];
        workingTmp.forEach(d => {
            const momentDate = moment(d);
            if(momentDate.day() === 6) {
                working.push(momentDate.clone().add(1, 'day').format('YYYY-MM-DD'));
            } else if(momentDate.day() === 0) {
                working.push(momentDate.clone().subtract(1, 'day').format('YYYY-MM-DD'));
            }
        })

        return [...new Set(working)].filter(d => days.includes(d)).length
    }

    /**
     * Task select handler method. Once a task is selected, we generate the holidays in the turnus period which
     * again triggers generate of the columns displayed in the holiday planner
     * @param selectedTaskOption
     * @returns {Promise<void>}
     */
    const handleSelectTaskChange = async (selectedTaskOption) => {
        if(!selectedTaskOption) {
            reset();
            return processAndSetHolidays(department, moment().startOf('year').startOf('isoWeek'), 157);
        }

        setColumns(undefined);
        let t = await dynoapeAPI.get(`/api/v1/department/${departmentId}/task/${selectedTaskOption.value}`);
        setStartDate(t.config.startDate);
        setNrOfWeeks(t.config.nrOfWeeks);
        const _startDate = moment(t.config.startDate)
        const hol = processAndSetHolidays(department, _startDate, t.config.nrOfWeeks);
        setHolidayRequirements(getRequirementsFromTask(t, hol, 'HOLIDAYS'));
        setDays(hol.slice(0, nrInView));

        setSelectedTask(t);
        setVacationsByEmployeeAndHoliday(getVacationsForDaysAndEmployees(hol))
        setSelectedTaskOption({value: selectedTaskOption.value, label: selectedTaskOption.label});
        setViewStartIndex(0)
        setViewFlipSize(2);
        scrollHeight()
    }

    useEffect(() => {
        setDays(holidays.slice(0, nrInView))
    }, [holidays])

    /**
     * UseEffect for generating which weeks that should be present in the holiday planner.
     * Triggered whenever user viewStartIndex is changed (user selects to jump weeks)
     */
    useEffect(() => {
        setDays(holidays.slice(viewStartIndex, viewStartIndex + nrInView))
    }, [viewStartIndex])

    /**
     * Method for handling the changes in the sheet
     * @param changes
     */
    const handleChanges = (changes) => {
        findAndSetOpenedDropdownCellLocation(changes);
        let localToSave = toSave;
        changes.forEach(ch => {
            if(ch.newCell.isOpen && !ch.previousCell.isOpen) return;

            const employee = (localToSave.find(e => e.id === ch.rowId) || employees.find(e => e.id === ch.rowId));
            if(['redDaysMaxSequence', 'movableHolidaysMaxPerTurnus'].includes((ch.columnId))) {
                const val = Number(ch.newCell.text);
                if (!isNaN(val)) {
                    employee[ch.columnId] = val;
                    localToSave = localToSave.filter(e => e.id !== employee.id).concat(employee);
                }
            }
            const date = moment(ch.columnId).format('YYYY-MM-DD');
            const pattern = validHolidayPattern((employee.holidayPatterns || []).find(p => p))
            if (ch.newCell.checked === true) {
                if(!pattern.includeDays.includes(date)) {
                    pattern.includeDays.push(date)
                }
            } else {
                if(pattern.includeDays.includes(date)) {
                    pattern.includeDays = pattern.includeDays.filter(d => d !== date);
                }
            }
            localToSave = [...localToSave.filter(e => e.id !== employee.id), {...employee, holidayPatterns: [pattern].filter(p => p)}]
        });
        setToSave(toSave.filter(e => !localToSave.find(le => le.id === e.id)).concat(localToSave))
    }

    /**
     * Method for saving employees and respective holiday patters
     * @returns {Promise<void>}
     */
    const save = async () => {
        setShowSavingModal({ display: true, saveCount: 0, toSave: toSave.length });
        for(const e of toSave) {
            setShowSavingModal({ display: true, saveCount: showSavingModal.saveCount++, toSave: toSave.length, employee: e.name});
            for (const pattern of e.holidayPatterns) {
                const id = pattern.id;
                ['id', 'registered', 'updated'].forEach(field => delete pattern[field])
                if (id) {
                    await dynoapeAPI.put(`/api/v1/department/${departmentId}/employee/${e.id}/pattern/${id}?type=${patternCase}`, pattern);
                } else {
                    await dynoapeAPI.post(`/api/v1/department/${departmentId}/employee/${e.id}/pattern?type=${patternCase}`, pattern);
                }
            }
            await dynoapeAPI.emPatch(`/api/v1/department/${departmentId}/employee/${e.id}`,
                {
                    redDaysMaxSequence: e.redDaysMaxSequence,
                    movableHolidaysMaxPerTurnus: e.movableHolidaysMaxPerTurnus});
        }
        setToSave([]);
        setShowSavingModal({ display: false, saveCount: 0, toSave: 0, employee: null })
        setEmployees((await dynoapeAPI.get(`/api/v1/department/${departmentId}/employees${PatternCase.getIncludeQueryParams(patternCase)}`)).filter(e => e.enabled).sort(function (a, b) {
            return a.priority === b.priority ? new Date(a.registered) - new Date(b.registered) : a.priority - b.priority
        }))
    }

    return (
        <PlannerWrapper>
            <p><label style={{fontSize: "16px"}}>
                Her kan du planlegge hvilke helligdager hver ansatt skal jobbe.
                <br/>
                <b>Merk: </b>
                <ul>
                    <li>For å blokkere en helligdag, gå til {PatternCase.translate(PatternCase.BLOCKED_PATTERN)}</li>
                    <li>Helligdager som treffer en helgedag, må planlegges i {PatternCase.translate(PatternCase.WEEKEND_PATTERN)}</li>
                </ul>
            </label></p>
            <br/>
            <Flex flexDirection="row">
                <Button primary style={{marginTop: "0px" }} onClick={() => save()}
                        data-for="save"
                        disabled={toSave.length === 0}
                        data-tip="Lagre dine helligdags-spesifikasjoner for de ansatte">Lagre</Button>
                <Tooltip id="save" />
                {taskSelect(handleSelectTaskChange)}
                {searchBar()}
                {columns &&
                    <WeekJumper flipSize={viewFlipSizeRef.current} setFlipSize={setViewFlipSize}
                                viewStartIndex={viewStartIndex} setViewStartIndex={setViewStartIndex}
                                nrInView={nrInView} jumpTitle="Hopp antall uker" totalNrOfColumns={holidays.length}>
                    </WeekJumper>
                }
            </Flex>
            {columns === undefined && <p>Laster...</p>}
            {columns && <ReactGridWrapper>
                <ReactGrid
                    customCellTemplates={{
                        'dropdown-number': DropdownCellTemplate,
                        'checkbox': CheckboxCellTemplate,
                        'dropdown': DropdownSelectCellTemplate,
                        'empty': DisabledInfoCellTemplate,
                        'empty-pink': DisabledInfoCellTemplate
                    }}
                    rows={[
                        ...(selectedTask ? [getReactGridHeaderRow(columns, 'turnusWeek', 'turnusWeek', 50)] : []),
                        getReactGridHeaderRow(columns, 'header', 'text', 70),
                        ...getReactGridRows(columns, employees.map(e => getLocalOrGlobalEmployee(e.id)), days[0], days[days.length -1]).map((row, idx) => idx % 2 !== 0 ?
                            { ...row, cells: row.cells.map(cell => ({ ...cell, style: {  background: cell.type === 'empty-pink' ? 'pink' : 'rgba(0,0,0,0.07)',
                                        color: !row._employee.enabled ? "#888" : "black" } })) }
                            : { ...row, cells: row.cells.map(cell => ({ ...cell, style: { color: !row._employee.enabled ? "#888" : "black", background: cell.type === 'empty-pink' ? 'pink' : "" } })) }
                        ),
                        getReactGridHeaderRow(columns, 'demand', 'demand'),
                        getReactGridHeaderRow(columns, 'cover', 'cover'),
                        [getReactGridHeaderRow(columns, 'diff', 'diff')]
                            .map(row => ({...row, cells: row.cells
                                    .map(cell => ({...cell, text: cell.text.replaceAll('+', ''), style: {color:
                                                cell.text === "Differanse" ? "black" :
                                                    cell.text.includes("+") ? "blue" :
                                                        Number(cell.text) < 0 ? "red": "green"}}))}))[0]
                    ]}
                    columns={columns}
                    stickyTopRows={selectedTask ? 2 : 1}
                    stickyBottomRows={3}
                    stickyLeftColumns={4}
                    onCellsChanged={handleChanges}
                    enableFillHandle
                    enableRangeSelection
                    enableRowSelection
                />
            </ReactGridWrapper>}
            {showSavingModal.display && saveModal()}
            {routerPrompt(toSave.length > 0)}
            {dynomiteErrorPanel(dynomite, configErrors, dynomiteInternalError)}
            {dynomiteErrorPanel(dynomite, configWarnings, dynomiteInternalError, 0, () => {}, true)}
        </PlannerWrapper>
    )
}