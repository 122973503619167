
import React, { useEffect, useState } from "react";
import { Spinner, Button } from "rendition";
import { dynoape, dynoapeAPI } from "../api/dynoape";
import { useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { Box, Txt } from "rendition";
import Accordion from "./accordion";
import { dateInNorwegianTimezone, taskStuck, TaskCardWaitingSpinner } from "../lib/common";
import styled from "styled-components";
import Tooltip from "../lib/tooltip";

const ResultTable = props => {
  const [isLoading, setIsLoading] = useState(true);
  const [task, setTask] = useState();
  const [result, setResult] = useState();
  const [resultCount, setResultCount] = useState(0);
  const [downloading, setDownloading] = useState(false);
  const [singleDownloading, setSingleDownloading] = useState([]);
  const [downloads, setDownloads] = useState(0);
  const [failedDownloads, setFailedDownloads] = useState([]);
  const { departmentId, taskId } = useParams();
  const { getAccessTokenSilently } = useAuth0();

  const getData = async () => {
    const [
      taskData,
      resultsData
    ] = await Promise.all([
      dynoapeAPI.get(`/api/v1/department/${departmentId}/task/${taskId}`),
      dynoapeAPI.get(`/api/v1/department/${departmentId}/task/${taskId}/result`),
    ]);
    setTask(taskData);

    if(Object.entries(resultsData).length > 0) {
      setResult(resultsData);
      setResultCount(resultsData.resultNr);
    }
    setIsLoading(false)
  };

  const downloadResult = async () => {
    setDownloading(true);
    setDownloads(0);
    setSingleDownloading(['TP', 'EMPLOYEE', 'EMPLOYEE_COVER_OVERVIEW', 'SIMPLE_TP', 'RULE_BREACH', 'EMPLOYEE_TURNUS'])
    setFailedDownloads([]);
    download(['TP'], 'turnusplan', true);
    download(['EMPLOYEE'], 'ansatte', true);
    download(['EMPLOYEE_COVER_OVERVIEW'], 'vaktbok', true);
    download(['SIMPLE_TP'], 'vaktkode-turnus', true);
    download(['RULE_BREACH'], 'avvik', true)
    download(['EMPLOYEE_TURNUS'], 'personlig-turnus', true)
  };

  const nrOfFiles = 6;

  useEffect(() => {
    if(downloads + failedDownloads.length === nrOfFiles) {
      setDownloading(false)
      setDownloads(0);
    }
  }, [downloads]);

  const download = async (sheets, namePostfix, all) => {
    const token = await getAccessTokenSilently();
    setSingleDownloading(sd => !all ? [...sd, sheets.join(",")] : sd);

    try {
      const response = await dynoape.get(`/api/v1/department/${departmentId}/task/${taskId}/result/export?includeSheets=${sheets}`,
          {
            method: 'GET',
            responseType: 'blob',
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
      );

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', props.task.name + '-' + namePostfix + '.xlsx'); //or any other extension
      document.body.appendChild(link);
      link.click();
      setDownloads(downloads => all ? downloads + 1 : downloads)
    } catch (error) {
      setFailedDownloads(all ?[...failedDownloads, namePostfix] : failedDownloads);
      console.error(error);
    }
    setSingleDownloading(sd => sd.filter(d => d !== sheets.join(",")));
  }

  const totalNrOfBreaches = () => {
    let workload = result.penalties.basic_workload_average?.values.length || 0
    let startTimesCover = result.penalties.employees_start_time?.values.length || 0;
    let endTimesCover = result.penalties.employees_end_time?.values.length || 0;
    let prefer_d_before_f1 = result.penalties.prefer_d_before_f1?.values.length || 0;
    let prefer_a_after_f2 = result.penalties.prefer_a_after_f2?.values.length || 0;
    let working_days_seq_min = result.penalties.working_days_seq_min?.values.length || 0;
    return result.nrOfBreaches - workload - startTimesCover -
        endTimesCover - prefer_d_before_f1 - prefer_a_after_f2 - working_days_seq_min;
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      {isLoading ? <Spinner show /> : !result ?
        <>
          {(task.status === "STARTING" || task.status === "PROCESSING") && !taskStuck(task) && <TaskCardWaitingSpinner style={{ float: "right", paddingRight: "20px", paddingTop: "40px" }} label="Genererering pågår" />}
          <div style={{ background: "white", padding: "10px 20px 20px 10px" }}><h4>Ingen resultater funnet</h4></div>
        </>
        : <div>
          {result && <StatsPanel>
            <StatsPanelHeader>
              <span
                data-for="results-best"
                data-tip="Her finner du statistikk for den mest optimerte turnusen som er generert til nå<br />
                  Statistikken viser avvik på data du har lagt inn i modul for “Bemanning”<br />
                  Det er ikke er avvik på lovverk, sentrale- eller lokale avtaler som til enhver tid gjelder, men brudd i forhold til det som er spesifisert i modul for “Bemanning”"
              >
                <h3>Beste resultat</h3>
                <Tooltip id="results-best" />
              </span>
              <div>
                {downloading && downloading === result.id ? <Spinner style={{ height: "40px", float: "right", paddingRight: "20px", paddingTop: "5px" }}
                                                                         label={"Vennligst vent. Generering av excel kan ta noen minutter.  " + downloads + "/" + nrOfFiles + " ferdig. "
                                                                             + (failedDownloads.length > 0 ? "Følgende har feilet: " + failedDownloads.join() : "")} /> :
                    <div>
                      {(!singleDownloading || singleDownloading.length === 0) &&<Button
                          primary
                          onClick={() => downloadResult()}
                          data-for="results-download"
                          data-tip="Last ned turnus til Excel"
                          style={{ float: "right", marginLeft: "20px" }}
                      >Last ned alle</Button>}
                      {singleDownloading && singleDownloading.includes('EMPLOYEE')
                          ? <Spinner style={{height: "40px", marginRight: "5px", float: "right"}} label={"Laster ned ansatte"}></Spinner>
                          :<Button
                              quartenary
                              onClick={() => download(['EMPLOYEE'], 'ansatte')}
                              data-for="results-download"
                              data-tip="Last ned ansatte til Excel"
                              style={{ float: "right", marginRight: "2px" }}
                          >Ansatte</Button>}
                      {singleDownloading && singleDownloading.includes('RULE_BREACH')
                          ? <Spinner style={{height: "40px", marginRight: "5px", float: "right"}} label={"Laster ned avvik"}></Spinner>
                          :<Button
                              quartenary
                              onClick={() => download(['RULE_BREACH'], 'avvik')}
                              data-for="results-download"
                              data-tip="Last ned avvik til Excel"
                              style={{ float: "right", marginRight: "2px" }}
                          >Avvik</Button>}
                      {singleDownloading && singleDownloading.includes('SIMPLE_TP')
                          ? <Spinner style={{height: "40px", marginRight: "5px", float: "right"}} label={"Laster ned turnus med vaktkoder"}></Spinner>
                          :<Button
                              quartenary
                              onClick={() => download(['SIMPLE_TP'], 'vaktkode-turnus')}
                              data-for="results-download"
                              data-tip="Last ned turnus med vaktkoder til Excel"
                              style={{ float: "right", marginRight: "2px" }}
                          >Turnus med vaktkoder</Button>}
                      {singleDownloading && singleDownloading.includes('EMPLOYEE_COVER_OVERVIEW')
                          ? <Spinner style={{height: "40px", marginRight: "5px", float: "right"}} label={"Laster ned vaktbok"}></Spinner>
                          :<Button
                              quartenary
                              onClick={() => download(['EMPLOYEE_COVER_OVERVIEW'], 'vaktbok')}
                              data-for="results-download"
                              data-tip="Last ned vaktbok til Excel"
                              style={{ float: "right", marginRight: "2px" }}
                          >Vaktbok</Button>}
                      {singleDownloading && singleDownloading.includes('EMPLOYEE_TURNUS')
                          ? <Spinner style={{height: "40px", marginRight: "5px", float: "right"}} label={"Laster ned personlig turnus"}></Spinner>
                          :<Button
                              quartenary
                              onClick={() => download(['EMPLOYEE_TURNUS'], 'personlig-turnus')}
                              data-for="results-download"
                              data-tip="Last ned personlig turnus til Excel"
                              style={{ float: "right", marginRight: "2px" }}
                          >Personlig turnus</Button>}
                      {singleDownloading && singleDownloading.includes('TP')
                          ? <Spinner style={{height: "40px", marginRight: "5px", float: "right"}} label={"Laster ned turnus"}></Spinner>
                          : <Button
                              quartenary
                              onClick={() => download(['TP'], 'turnusplan')}
                              data-for="results-download"
                              data-tip="Last ned turnus til Excel"
                              style={{ float: "right", marginRight: "2px" }}
                          >Turnusplan</Button>}</div>
                }
                {task.status === "PROCESSING" && !taskStuck(task) && <TaskCardWaitingSpinner style={{ float: "right", paddingRight: "20px", paddingTop: "5px" }} label="Genererering pågår" />}
              </div>
              <Tooltip id="results-download" />
            </StatsPanelHeader>
            {props.task && props.task.started && <StatsPart>Generering startet: {dateInNorwegianTimezone(props.task.started)}</StatsPart>}
            <StatsPart>Resultat funnet: {dateInNorwegianTimezone(result.registered)}</StatsPart>
            <StatsPart>Antall resultater generert: {resultCount}</StatsPart>
            <StatsPart
              data-for="results-total"
              data-tip="Her vises totalt antall avvik på Bemanningsplanen som er laget for denne turnusen. Når turnusen lastes ned, finnes en spesifisert oversikt over hvor de ulike avvikene er i turnusen i arkfanen “Regelavvik”"
            >Antall avvik totalt: {totalNrOfBreaches()}<Tooltip id="results-total" /></StatsPart>

            <Box small style={{marginTop: "2em"}}>
              <Accordion
                items={[
                  {
                    label: <Txt bold>Vis analyse</Txt>,
                    panel: <>
                    {
                      ((result.penalties.weekly_cover_demands_min?.values.length || 0) > 0 ||
                          (result.penalties.weekly_cover_demands_max?.values.length || 0) > 0 ||
                          (result.penalties.weekly_cover_pos_demands?.values.length || 0) > 0 ||
                          (result.penalties.traits_employees?.values.length || 0) > 0 ||
                          (result.penalties.responsible_employees?.values.length || 0) > 0
                      ) &&
                      <StatsPartHeader>Bemanningsplan-avvik</StatsPartHeader>
                    }
                      {(result.penalties.weekly_cover_demands_min?.values.length || 0) > 0 && <StatsPart
                        data-for="results-minimum"
                        data-tip="Her vises hvor mange ganger det er for få ansatte på vakt i forhold til det som er satt som minimumskrav i Bemanningsplanen"
                      >Avvik på min. bemanningskrav: {result.penalties.weekly_cover_demands_min?.values.length || 0}<Tooltip id="results-minimum" /></StatsPart>}
                      {(result.penalties.weekly_cover_demands_max?.values.length || 0) > 0 && <StatsPart
                        data-for="results-max"
                        data-tip="Her vises hvor mange ganger det er for mange ansatte på vakt i forhold til det som er satt som maksimumskrav i Bemanningsplanen"
                      >Avvik på maks. bemanningskrav: {result.penalties.weekly_cover_demands_max?.values.length || 0}<Tooltip id="results-max" /></StatsPart>}
                      {(result.penalties.weekly_cover_pos_demands?.values.length || 0) > 0 && <StatsPart
                          data-for="results-core"
                          data-tip="Her vises hvor mange ganger det ikke er nok ansatte til å dekke krav til riktig spesialkompetanse i kjernetiden i  Bemanningsplanen"
                      >Avvik på bemanningskrav i kjernetid: {result.penalties.weekly_cover_pos_demands?.values.length || 0}<Tooltip id="results-core" /></StatsPart>}
                      {(result.penalties.traits_employees?.values.length || 0) > 0 && <StatsPart
                          data-for="results-special"
                          data-tip="Her vises hvor mange ganger det ikke er nok ansatte med spesialkompetanse til å dekke kravet som er satt i  Bemanningsplanen"
                      >Avvik på krav til spesialkompetanse: {result.penalties.traits_employees?.values.length || 0}<Tooltip id="results-special" /></StatsPart>}
                      {(result.penalties.responsible_employees?.values.length || 0) > 0 && <StatsPart
                          data-for="results-responsible"
                          data-tip="Her vises hvor mange ganger det ikke er nok ansatte med spesialkompetanse til å dekke ansvarsvakt som er satt i  Bemanningsplanen"
                      >Avvik på krav til antall ansvarsvakter: {result.penalties.responsible_employees?.values.length || 0}<Tooltip id="results-responsible" /></StatsPart>}
                      <br/>
                    {(
                            (result.penalties.maximum_shifts?.values.length || 0) > 0 ||
                            (result.penalties.shift_constraints_day_shift_max?.values.length || 0) > 0 ||
                            (result.penalties.day_shift_max_per_week?.values.length || 0) > 0 ||
                            (result.penalties.shift_constraints_evening_shift_max?.values.length || 0) > 0 ||
                            (result.penalties.evening_shift_max_per_week?.values.length || 0) > 0 ||
                            (result.penalties.shift_constraints_long_shift_max?.values.length || 0) > 0 ||
                            (result.penalties.long_shift_max_per_week?.values.length || 0) > 0 ||
                            (result.penalties.night_shift_max_per_week?.values.length || 0) > 0 ||
                            (result.penalties.shift_constraints_night_shift_max?.values.length || 0) > 0 ||
                            (result.penalties.day_segment_week_maximum?.values.length || 0) > 0 ||
                            (result.penalties.day_segment_maximum?.values.length || 0) > 0 ||
                            (result.penalties.night_shift_max_weekends?.values.length || 0) > 0 ||
                            (result.penalties.max_holidays_count?.values.length || 0) > 0 ||
                            (result.penalties.max_red_days_in_a_row?.values.length || 0) > 0 ||
                            (result.penalties.blocked_day_segments?.values.length || 0) > 0 ||
                            (result.penalties.working_day_segments?.values.length || 0) > 0 ||
                            (result.penalties.blocked_non_refundable_shifts?.values.length || 0) > 0
                        ) &&
                      <StatsPartHeader>Ansatt-avvik</StatsPartHeader>
                    }
                      {(result.penalties.maximum_shifts?.values.length || 0) > 0 && <StatsPart
                          data-for="results-total-shifts"
                          data-tip="Her vises total antall avvik på maks. antall vakter totalt i turnus"
                      >Avvik på maks. antall vakter i turnus: {result.penalties.maximum_shifts?.values.length || 0}<Tooltip id="results-total-shifts" /></StatsPart>}
                      <Tooltip id="results-working-days" />

                      {(result.penalties.shift_constraints_day_shift_max?.values.length || 0) > 0 && <StatsPart
                          data-for="results-maxDayShifts"
                          data-tip="Her vises totalt antall avvik på maksimum antall dagvakter i turnus. "
                      >Avvik på maks. antall dagvakter i turnus: {result.penalties.shift_constraints_day_shift_max?.values.length || 0}<Tooltip id="results-maxDayShifts" /></StatsPart>}
                      <Tooltip id="results-maxDayShifts" />

                      {(result.penalties.day_shift_max_per_week?.values.length || 0) > 0 && <StatsPart
                          data-for="results-day-shifts-per-week"
                          data-tip="Her vises antall avvik på maks. dagvakter per uke"
                      >Avvik på maks. dagvakter per uke: {result.penalties.day_shift_max_per_week?.values.length || 0}<Tooltip id="results-day-shifts-per-week" /></StatsPart>}
                      <Tooltip id="results-day-shifts-per-week" />

                      {(result.penalties.shift_constraints_evening_shift_max?.values.length || 0) > 0 && <StatsPart
                          data-for="results-maxEveningShifts"
                          data-tip="Her vises totalt antall avvik på maksimum antall kveldsvakter i turnus. "
                      >Avvik på maks. antall kveldsvakter i turnus: {result.penalties.shift_constraints_evening_shift_max?.values.length || 0}<Tooltip id="results-maxEveningShifts" /></StatsPart>}
                      <Tooltip id="results-maxEveningShifts" />

                      {(result.penalties.evening_shift_max_per_week?.values.length || 0) > 0 && <StatsPart
                          data-for="results-evening-shifts-per-week"
                          data-tip="Her vises antall avvik på maks. kveldsvakter per uke"
                      >Avvik på maks. kveldsvakter per uke: {result.penalties.evening_shift_max_per_week?.values.length || 0}</StatsPart>}
                      <Tooltip id="results-evening-shifts-per-week" />

                      {(result.penalties.shift_constraints_long_shift_max?.values.length || 0) > 0 && <StatsPart
                          data-for="results-maxLongShifts"
                          data-tip="Her vises totalt antall avvik på maksimum antall langvakter i turnus. "
                      >Avvik på maks. antall langvakter/mellomvakter i turnus: {result.penalties.shift_constraints_long_shift_max?.values.length || 0}<Tooltip id="results-maxLongShifts" /></StatsPart>}
                      <Tooltip id="results-maxLongShifts" />

                      {(result.penalties.long_shift_max_per_week?.values.length || 0) > 0 && <StatsPart
                          data-for="results-long-shifts-per-week"
                          data-tip="Her vises antall avvik på maks. langvakter per uke"
                      >Avvik på maks. langvakter per uke: {result.penalties.long_shift_max_per_week?.values.length || 0}</StatsPart>}
                      <Tooltip id="results-long-shifts-per-week" />

                      {(result.penalties.shift_constraints_night_shift_max?.values.length || 0) > 0 && <StatsPart
                          data-for="results-maxNightShifts"
                          data-tip="Her vises totalt antall avvik på maksimum antall nattevakter i turnus. "
                      >Avvik på maks. antall nattevakter i turnus: {result.penalties.shift_constraints_night_shift_max?.values.length || 0}</StatsPart>}
                      <Tooltip id="results-maxNightShifts" />

                      {(result.penalties.night_shift_max_per_week?.values.length || 0) > 0 && <StatsPart
                          data-for="results-night-shifts-per-week"
                          data-tip="Her vises antall avvik på maks. nattevakter per uke"
                      >Avvik på maks. nattevakter per uke: {result.penalties.night_shift_max_per_week?.values.length || 0}</StatsPart>}
                      <Tooltip id="results-night-shifts-per-week" />

                      {(result.penalties.day_segment_week_maximum?.values.length || 0) > 0 && <StatsPart
                          data-for="results-day-segment-week-maximum"
                          data-tip="Her vises antall avvik på maks. vakter per vaktkategori per uke"
                      >Avvik på maks. vakter per vaktkategori per uke: {result.penalties.day_segment_week_maximum?.values.length}</StatsPart>}
                      <Tooltip id="results-day-segment-week-maximum" />

                      {(result.penalties.day_segment_maximum?.values.length || 0) > 0 && <StatsPart
                          data-for="results-day-segment-maximum"
                          data-tip="Her vises antall avvik på maks. vakter per vaktkategori i turnus"
                      >Avvik på maks. vakter per vaktkategori i turnus: {result.penalties.day_segment_maximum?.values.length}</StatsPart>}
                      <Tooltip id="results-day-segment-maximum" />

                      {(result.penalties.night_shift_max_weekends?.values.length || 0) > 0 && <StatsPart
                          data-for="results-night-shifts-weekends"
                          data-tip="Her vises antall avvik på maks. nattevakthelger i turnus"
                      >Avvik på maks. nattevakthelger i turnus: {result.penalties.night_shift_max_weekends?.values.length || 0}</StatsPart>}
                      <Tooltip id="results-night-shifts-weekends" />

                      {(result.penalties.max_holidays_count?.values.length || 0) > 0 && <StatsPart
                          data-for="results-holidays"
                          data-tip="Her vises totalt antall avvik på maksimum antall bevegelige helligdager i turnus. Når turnusen lastes ned, finnes en oversikt over hvilken ansatt(e) det er avvik for"
                      >Avvik på maks. antall bevegelige høytidsdager i turnus: {result.penalties.max_holidays_count?.values.length || 0}</StatsPart>}
                      <Tooltip id="results-holidays" />

                      {(result.penalties.max_red_days_in_a_row?.values.length || 0) > 0 && <StatsPart
                          data-for="results-reddays"
                          data-tip="Her vises totalt antall avvik på maksimum antall røde dager på rad. "
                      >Avvik på maks. antall røde dager på rad: {result.penalties.max_red_days_in_a_row?.values.length || 0}</StatsPart>}
                      <Tooltip id="results-reddays" />

                      {(result.penalties.blocked_day_segments?.values.length || 0) > 0 && <StatsPart
                          data-for="results-blocked-days"
                          data-tip="Her vises totalt antall avvik på blokkerte dager. "
                      >Avvik på blokkerte dager: {result.penalties.blocked_day_segments?.values.length || 0}
                      </StatsPart>}
                      <Tooltip id="results-blocked-days" />

                      {(result.penalties.blocked_non_refundable_shifts?.values.length || 0) > 0 && <StatsPart
                          data-for="legacy-results-blocked-days"
                          data-tip="Her vises totalt antall avvik på blokkerte dager. "
                      >Avvik på blokkerte dager: {result.penalties.blocked_non_refundable_shifts?.values.length || 0}
                      </StatsPart>}
                      <Tooltip id="legacy-results-blocked-days" />

                      {(result.penalties.working_day_segments?.values.length || 0) > 0 && <StatsPart
                          data-for="results-working-days"
                          data-tip="Her vises totalt antall avvik på planlagte arbeidsdager. "
                      >Avvik på planlagte arbeidsdager: {result.penalties.working_day_segments?.values.length || 0}
                      </StatsPart>}
                      <Tooltip id="results-working-days" />
                    </>
                  }
                ]}
              />
            </Box>
          </StatsPanel>}
        </div>
      }
    </>
  );
};

const StatsPanel = styled.div`
  background-color: #FFF;
  border-radius: 10px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
  margin: 15px 0px;
  margin-bottom: 25px;
  padding: 1px 25px 25px 25px;
  width: 1350px;
`;

const StatsPanelHeader = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StatsPart = styled.div`
  color: #26565B;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  margin-top: 10px;
`;

const StatsPartHeader = styled.div`
  color: #26565B;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  margin-top: 10px;
`;

export default ResultTable;
