import React, { useEffect, useState } from "react";
import { Header, DepartmentSettings, ShiftTypesTable } from "../components";
import { BackButton } from "../components/buttons";
import { dynoapeAPI } from "../api/dynoape";
import { useParams, useLocation } from "react-router-dom";
import {Button, Card, Tabs, Tab, Spinner, notifications, Modal, ProgressBar, Checkbox, Input} from "rendition";
import {InputWithValidation, SettingsContainer} from "../lib/styled-components";
import Tooltip from "../lib/tooltip";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import {dateInNorwegianTimezone, maxNrOfDecimalRegex} from "../lib/common";
import moment from "moment";
import styled from "styled-components";
import _ from "lodash";

const DateDot = () => {
  return <div
    style={{
      height: "5px",
      width: "5px",
      borderRadius: "100%",
      background: "red",
      position: "relative",
      float: "right",
      top: "9px",
      right: "7px"
    }}
  />
}

const Department = () => {
  const { hash } = useLocation();
  const loc = hash.slice(1);
  const [activeIndex, setActiveIndex] = useState(loc == "oppsett" ? 1 : 0)

  const [department, setDepartment] = useState({});
  const [license, setLicense] = useState();
  const [licensePeriod, setLicensePeriod] = useState();
  const [holidays, setHolidays] = useState({});
  const [employees, setEmployees] = useState([]);
  const [countryCodeRules, setCountryCodeRules] = useState([]);
  const [fullVacancyRateHours, setFullVacancyRateHours] = useState();
  const [displayNightShiftOnNextDayInResult, setDisplayNightShiftOnNextDayInResult] = useState();
  const [showF3MinVacancyRate, setShowF3MinVacancyRate] = useState(true);
  const [f3MinVacancyRate, setF3MinVacancyRate] = useState();
  const [f3WorkHours, setF3WorkHours] = useState();
  const [f1FreeHours, setF1FreeHours] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [displayError, setDisplayError] = useState(false);
  const [showSavingModal, setShowSavingModal] = useState({ display: false, saveCount: 0, toSave: 0, errorMessages: [] });
  const { departmentId } = useParams();

  //needed until we can fix employee patch endpoint
  const deleteValues = ["id", "departmentId", "traitNames", "registered", "updated", "restPosition"];

  const getData = async () => {
    const [
      { name, config, countryRules },
      employeesData,
      countryCodeRules,
      licenseData
    ] = await Promise.all([
      dynoapeAPI.get(`/api/v1/department/${departmentId}`),
      dynoapeAPI.get(`/api/v1/department/${departmentId}/employees`),
      dynoapeAPI.get(`/api/v1/countryrules`),
      dynoapeAPI.get(`/api/v1/department/${departmentId}/active-license`)
    ]);
    if (config.maxConsecutiveShifts === null)
      config.maxConsecutiveShifts = 5;

    setDepartment({ name, config, countryRulesId: countryRules ? countryRules.id : countryCodeRules[0].id });
    setEmployees(employeesData);
    setCountryCodeRules(countryCodeRules);
    setFullVacancyRateHours(config.fullVacancyRateHours
        ? validVacancyRateValues.includes(config.fullVacancyRateHours.toString()) ? config.fullVacancyRateHours
            : ""  : "");
    setDisplayNightShiftOnNextDayInResult(config.displayNightShiftOnNextDayInResult || false);
    setF3MinVacancyRate(config.f3MinVacancyRate);
    setShowF3MinVacancyRate(!(parseFloat(config.f3MinVacancyRate) >= 100.01))
    setF3WorkHours(config.f3WorkHours !== null
        ? config.f3WorkHours
        : "");
    setF1FreeHours(config.f1FreeHours);
    if (countryRules)
      setHolidays(countryRules.holidays)

    if(licenseData) {
      setLicense(licenseData);
      setLicensePeriod(parsePeriod(licenseData.period));
    }

    setIsLoading(false);
  };

  const parsePeriod = (period) => {
    let dur = moment.duration(period);
    let periodStr = "";
    if(dur.years() > 0) {
      periodStr += dur.years() + " år, ";
    }
    if(dur.months() > 0) {
      periodStr += dur.months() + " måneder, "
    }
    if(dur.weeks() > 0) {
      periodStr += dur.weeks() + " uker, "
    }
    if(dur.days() > 0) {
      periodStr += dur.days() + " dager, ";
    }
    return periodStr.substring(0, periodStr.length -2);
  }

  useEffect(() => {
    getData();
  }, []);

  const setMaxConsecutiveShifts = (maxConsecutiveShifts) => {
    department.config.maxConsecutiveShifts = maxConsecutiveShifts;
  }

  const setCountryCode = (countryRulesId) => {
    department.countryRulesId = countryRulesId;
  }

  const validVacancyRateValues = ["33.6", "35.5", "37.5", "40"];
  const validF3WorkHoursValues =
      [
        {value: "", label: "-"},
        {value: "0", label: "0"},
        {value: "7", label: "7"},
        {value: "7.1", label: "7.1"},
        {value: "7.5", label: "7.5"},
        {value: "8", label: "8"}
      ];

  const errors = () => {
    return !validVacancyRateValues.includes(fullVacancyRateHours.toString())
        || !_.inRange(parseFloat(f3MinVacancyRate), 0.0, 100.2)
        || !validF3WorkHoursValues.map(op => op.value).includes(f3WorkHours.toString())
        || !_.inRange(parseInt(f1FreeHours), 28, 49);
  }

  const prepDepartment = async () => {
    if (errors()) return;
    let errorMessages = [];
    let toSave = 0;

    if (employees.find(e => e.shiftMaxSequence > department.config.maxConsecutiveShifts)) {
      errorMessages.push(`${employees.filter(e => e.shiftMaxSequence > department.config.maxConsecutiveShifts).length} ansatte har maks. antall vakter på rad som er høyere enn ${department.config.maxConsecutiveShifts}, disse må settes ned`);
      toSave += employees.filter(e => e.shiftMaxSequence > department.config.maxConsecutiveShifts).length;
    }
    if (employees.find(e => e.dayShiftMaxSequence > department.config.maxConsecutiveShifts)) {
      errorMessages.push(`${employees.filter(e => e.dayShiftMaxSequence > department.config.maxConsecutiveShifts).length} ansatte har maks. dagvakter på rad som er høyere enn ${department.config.maxConsecutiveShifts}, disse må settes ned`);
      toSave += employees.filter(e => e.dayShiftMaxSequence > department.config.maxConsecutiveShifts).length;
    }
    if (employees.find(e => e.eveningShiftMaxSequence > department.config.maxConsecutiveShifts)) {
      errorMessages.push(`${employees.filter(e => e.eveningShiftMaxSequence > department.config.maxConsecutiveShifts).length} ansatte har maks. kveldsvakter på rad som er høyere enn ${department.config.maxConsecutiveShifts}, disse må settes ned`);
      toSave += employees.filter(e => e.eveningShiftMaxSequence > department.config.maxConsecutiveShifts).length;
    }
    if (employees.find(e => e.longShiftMaxSequence > department.config.maxConsecutiveShifts)) {
      errorMessages.push(`${employees.filter(e => e.longShiftMaxSequence > department.config.maxConsecutiveShifts).length} ansatte har maks. langvakter/mellomvakter på rad som er høyere enn ${department.config.maxConsecutiveShifts}, disse må settes ned`);
      toSave += employees.filter(e => e.longShiftMaxSequence > department.config.maxConsecutiveShifts).length;
    }
    if (employees.find(e => e.nightShiftMaxSequence > department.config.maxConsecutiveShifts)) {
      errorMessages.push(`${employees.filter(e => e.nightShiftMaxSequence > department.config.maxConsecutiveShifts).length} ansatte har maks. nattevakter på rad som er høyere enn ${department.config.maxConsecutiveShifts}, disse må settes ned`);
      toSave += employees.filter(e => e.nightShiftMaxSequence > department.config.maxConsecutiveShifts).length;
    }
    if (employees.find(e => e.weekendShiftMaxSequence && e.weekendShiftMaxSequence > department.config.maxConsecutiveShifts)) {
      errorMessages.push(`${employees.filter(e => e.weekendShiftMaxSequence && e.weekendShiftMaxSequence > department.config.maxConsecutiveShifts).length} ansatte har maks. antall vakter på rad rundt helg som er høyere enn ${department.config.maxConsecutiveShifts}, disse må settes ned`);
      toSave += employees.filter(e => e.weekendShiftMaxSequence && e.weekendShiftMaxSequence > department.config.maxConsecutiveShifts).length;
    }
    if (errorMessages.length > 0) {
      setDisplayError(true);
      setShowSavingModal({ ...showSavingModal, toSave, errorMessages });
    } else {
      saveDepartment();
    }
  }

  const saveDepartment = async () => {
    setIsSaving(true);
    department.config.displayNightShiftOnNextDayInResult = displayNightShiftOnNextDayInResult;
    department.config.fullVacancyRateHours = fullVacancyRateHours;
    department.config.f3MinVacancyRate = f3MinVacancyRate;
    department.config.f3WorkHours = f3WorkHours;
    department.config.f1FreeHours = f1FreeHours;
    await dynoapeAPI.put(`/api/v1/department/${departmentId}`, department);
    setIsSaving(false);
    getData();
    notifications.addNotification({
      content: "Oppsett lagret",
      duration: 2000,
      container: "center",
      type: "success"
    });
  }

  const updateEmployees = async () => {
    setShowSavingModal({ ...showSavingModal, display: true });

    await Promise.all(employees.map(async (e) => {
      let employeePatched = false;

      if (e.shiftMaxSequence > department.config.maxConsecutiveShifts) {
        e.shiftMaxSequence = department.config.maxConsecutiveShifts;
        employeePatched = true;
      }
      if (e.dayShiftMaxSequence > department.config.maxConsecutiveShifts) {
        e.dayShiftMaxSequence = department.config.maxConsecutiveShifts;
        employeePatched = true;
      }
      if (e.eveningShiftMaxSequence > department.config.maxConsecutiveShifts) {
        e.eveningShiftMaxSequence = department.config.maxConsecutiveShifts;
        employeePatched = true;
      }
      if (e.longShiftMaxSequence > department.config.maxConsecutiveShifts) {
        e.longShiftMaxSequence = department.config.maxConsecutiveShifts;
        employeePatched = true;
      }
      if (e.nightShiftMaxSequence > department.config.maxConsecutiveShifts) {
        e.nightShiftMaxSequence = department.config.maxConsecutiveShifts;
        employeePatched = true;
      }

      if (e.weekendShiftMaxSequence && e.weekendShiftMaxSequence > department.config.maxConsecutiveShifts) {
        e.weekendShiftMaxSequence = department.config.maxConsecutiveShifts;
        employeePatched = true;
      }

      if (employeePatched) {
        let employeeId = e.id;
        e.position = e.restPosition.id;
        deleteValues.forEach(dv => {
          delete e[dv];
        });
        e.shiftTypes = e.restShiftTypes.map(r => r.id);
        delete e.restShiftTypes;
        delete e.shiftTypeCodes;
        delete e.orgEmployeeId;
        e.weekendPatterns.forEach(p => delete p.id);
        await dynoapeAPI.put(`/api/v1/department/${departmentId}/employee/${employeeId}`, e);
        setShowSavingModal({ ...showSavingModal, saveCount: showSavingModal.saveCount++ });
      }
    })).then(() => {
      saveDepartment();
      setShowSavingModal({ display: false, saveCount: 0, toSave: 0, errorMessages: [] });
    });
  }

  var countryCodeOptions = [];
  countryCodeRules.map(c => countryCodeOptions.push(<option key={`opt-${c.id}`} value={c.id}>{c.countryCode}</option>));

  return (<>
    {!isLoading && <div>
      <Header title={"Avdelingsoppsett"}
        withTooltip
        tooltipId="settings-header"
        tooltipContent="I avdelingsoppsett kan du definere navnet på stillinger og spesialkompetanser. Du kan også legge inn de bevegelige helligdagene, samt hovedferien i avdelingen">
        <BackButton />
      </Header>
      <div className="content">
        <Tabs activeIndex={activeIndex} onActive={i => setActiveIndex(i)}>
          <Tab title="Avdelingsoppsett">
            <Card style={{ width: "1400px", borderTopLeftRadius: "0px" }}>
              <SettingsContainer>
                <h3>Avdelingsoppsett</h3>
                <div>
                  <p><label htmlFor={"maxConsecutiveShifts"}>Maks. antall vakter på rad</label></p>
                  <Select
                      id="maxConsecutiveShifts"
                      defaultValue={department.config.maxConsecutiveShifts}
                      onChange={(e) => setMaxConsecutiveShifts(e.target.value)}
                      data-for="maxConsecutiveShifts"
                      data-tip="Sett maks antall vakter som en ansatt kan ha på rad etter hverandre i turnus"
                  >
                    <option value={5}>5</option>
                    <option value={6}>6</option>
                    <option value={7}>7</option>
                    <option value={8}>8</option>
                    <option value={9}>9</option>
                  </Select>
                  <Tooltip id="maxConsecutiveShifts" />


                  <p style={{marginTop: "40px"}}><label htmlFor={"fullVacancyRateHours"}>Velg antall timer pr uke som gjelder for din avdeling
                    <br /><div style={{ color: "rgb(48 105 112)" }}>Hvis du ikke velger timetall, vil det automatisk være 35,5 timer som blir satt for din avdeling</div></label></p>
                  <Select
                      id="fullVacancyRateHours"
                      defaultValue={fullVacancyRateHours}
                      data-for="fullVacancyRateHours"
                      data-tip="Dynamon vil bruke det timetallet du velger beregningsgrunnlag for antall timer pr uke for alle ansatte i avdelingen"
                      style={{ width: "162px" }}
                      onChange={(e) => {
                        setFullVacancyRateHours(e.target.value);
                      }}
                  >
                    <option disabled selected value="">- Velg -</option>
                    <option value={33.6}>33.6</option>
                    <option value={35.5}>35.5</option>
                    <option value={37.5}>37.5</option>
                    <option value={40}>40.0</option>
                  </Select>
                  <Tooltip id="fullVacancyRateHours" />
                  {(!validVacancyRateValues.includes(fullVacancyRateHours.toString())) &&
                      <p style={{ color: "rgb(191, 61, 43)" }}>{fullVacancyRateHours} Uketimetall må være 33.6, 35.5, 37.5 eller 40</p>}

                  <p style={{marginTop: "40px"}}><label htmlFor={"f1FreeHours"}>Minimum antall (arbeids)timer fri rundt F1</label></p>
                  <InputWithValidation>
                    <Input
                        id={`f1FreeHours`}
                        key={`f1FreeHours`}
                        placeholder="28 til 48"
                        width="160px"
                        name="f1FreeHours"
                        value={f1FreeHours}
                        onChange={(e) => {
                          if(! e.target.value) {
                            setF1FreeHours("");
                          } else {
                            const val = parseInt(e.target.value);
                            setF1FreeHours(isNaN(val) ? "" : val);
                          }
                        }}
                        data-for="f1FreeHours"
                        data-tip="Her angir du minimum antall (arbeids)timer fri rundt F1"
                    />
                  </InputWithValidation>
                  <Tooltip id="f1FreeHours" />
                  {(isNaN(parseInt(f1FreeHours)) || !_.inRange(parseInt(f1FreeHours), 28, 49)) &&
                      <p style={{ color: "rgb(191, 61, 43)" }}>{"'Minimum antall (arbeids)timer fri rundt F1' " +
                          "må være mellom 28 og 48"}</p>}

                  <p style={{marginTop: "40px"}}><label htmlFor={"showF3MinVacancyRate"}>Skru på/av f3-dager i turnus
                    <br /><div style={{ color: "rgb(48 105 112)" }}> Her velger du om ansatte kan få f3 dager i turnusplanen</div>
                  </label></p>
                  <Checkbox
                      id={"showF3MinVacancyRate"}
                      data-for="showF3MinVacancyRate"
                      data-tip="Her velger du om ansatte kan få f3 dager i turnusplanen"
                      checked={showF3MinVacancyRate}
                      onChange={(e) => {
                        const checked = e.target.checked;
                        setShowF3MinVacancyRate(checked)

                        if(checked) {
                          setF3MinVacancyRate(1)
                        } else {
                          setF3MinVacancyRate(100.01)
                        }
                      }}
                  />

                  {showF3MinVacancyRate &&
                      <>
                        <p style={{marginTop: "40px"}}><label htmlFor={"f3MinVacancyRate"}>Minimum stillingsprosent for
                          at
                          F3 skal tildeles i turnus
                          <br/>
                          <div style={{color: "rgb(48 105 112)"}}>
                            Her velger du hvilke ansatte som kan få f3 dager i turnusplanen. Ved å skrive inn en
                            minimumsverdi for
                            stillingsprosent, <br/> vil kun ansatte med denne eller høyere stillingsprosent, kunne få f3
                            dager.
                          </div>
                        </label></p>
                        <InputWithValidation>
                          <Input
                              id={`f3MinVacancyRate`}
                              key={`f3MinVacancyRate`}
                              placeholder="0 til 100"
                              step="any"
                              width="160px"
                              name="f3MinVacancyRate"
                              value={f3MinVacancyRate}
                              onChange={(e) => setF3MinVacancyRate(e.target.value)}
                              data-for="f3MinVacancyRate"
                              data-tip="Her angir du minimum stillingsprosent for at F3 skal tildeles i turnus"
                          />
                        </InputWithValidation>
                        <Tooltip id="f3MinVacancyRate"/>
                        {!(_.inRange(parseFloat(f3MinVacancyRate), 0.0, 100.02) && maxNrOfDecimalRegex(2).test(f3MinVacancyRate)) &&
                            <p style={{color: "rgb(191, 61, 43)"}}>{"'Minimum stillingsprosent for at F3 skal tildeles i turnus' " +
                                "må være mellom 0 og 100 og kan maks ha 2 desimaler"}</p>}

                        <p style={{marginTop: "40px"}}><label htmlFor={"f3WorkHours"}>Helgedagsfri (F3) skal telles som i timer
                          <br /><div style={{color: "rgb(48 105 112)"}}>Hvis du ikke velger et timetall, beregner vi det for deg med utgangspunkt i 7.1</div>
                        </label></p>
                        <Select
                            id="f3WorkHours"
                            defaultValue={f3WorkHours.toString()}
                            data-for="f3WorkHours"
                            data-tip="Her legger du inn den avtalte tiden på helgedagsfri (F3)."
                            style={{width: "162px"}}
                            onChange={(e) => {
                              setF3WorkHours(e.target.value);
                            }}
                        >
                          {validF3WorkHoursValues.map(op => (<option key={op.value} value={op.value}>{op.label}</option>))}
                        </Select>
                        <Tooltip id="f3WorkHours" />
                        {(!validF3WorkHoursValues.map(op => op.value).includes(f3WorkHours.toString())) &&
                            <p style={{color: "rgb(191, 61, 43)"}}> Verdi må være en av {validF3WorkHoursValues.map(op => op.label).toString()}</p>}
                      </>
                  }
                  <p style={{marginTop: "40px"}}><label htmlFor={"displayNightShiftOnNextDayInResult"}>
                    Nattevakt skal vises på dagen den slutter i turnusplanen
                    <br /><div style={{ color: "rgb(48 105 112)" }}>Ved å skru på denne funksjonen, vil alle nattevakter i turnusplanen
                    vises på dagen den slutter, og ikke på dagen den starter.
                    <br/>
                    I bemanningsplanen må du forsatt sette opp kravet for dagen nattevakten starter.
                  </div></label></p>
                  <Checkbox
                      id={"displayNightShiftOnNextDayInResult"}
                      data-for="displayNightShiftOnNextDayInResult"
                      data-tip="Aktiver muligheten for å flytte nattevakter til neste dag i turnusplanen"
                      checked={displayNightShiftOnNextDayInResult}
                      onChange={(e) => setDisplayNightShiftOnNextDayInResult(e.target.checked)}
                  />
                  <p style={{marginTop: "40px"}}><label htmlFor={"countryCode"}>Landekode</label></p>
                  <Select
                      id="countryCode"
                      defaultValue={department.countryRulesId}
                      onChange={(e) => setCountryCode(e.target.value)}
                      data-for="countryCode"
                      data-tip="Sett NO for Norge"
                  >
                    {countryCodeOptions}
                  </Select>
                  <Tooltip id="countryCode" />

                </div>
                {Object.keys(holidays).length > 0 && <div>
                  <h3>Helligdager</h3>
                  <Calendar
                      style={{ height: 500 }}
                      tileContent={({ date }) => {
                        date.setDate(date.getDate() + 1)
                        if (new Set(Object.keys(holidays)).has(date.toISOString().split('T')[0]))
                          return <DateDot />
                      }}
                      showWeekNumbers
                      tileDisabled={() => true}
                      minDetail="month"
                      tileClassName={() => "calendar-active"}
                  >
                  </Calendar>
                </div>}
                {isSaving ? <Spinner style={{ marginTop: "20px" }} /> : <Button style={{ marginTop: "20px" }} primary onClick={() => prepDepartment()}>Lagre</Button>}
              </SettingsContainer>
            </Card>
          </Tab>
          <Tab title="Stillingskategorier &amp; Spesialkompetanser">
            <Card style={{ width: "1400px", borderTopLeftRadius: "0px" }}>
              <DepartmentSettings />
            </Card>
          </Tab>
          <Tab title="Vaktkoder">
            <Card style={{ width: "1400px", borderTopLeftRadius: "0px" }}>
              <ShiftTypesTable />
            </Card>
          </Tab>
          <Tab title="Lisensavtale">
            {license && <Card style={{ width: "1400px", borderTopLeftRadius: "0px" }}>
              <SettingsContainer>
                <h3>Aktiv lisensavtale {license.autorenewStatus === 'TERMINATED' ? '(stoppet)' : ''}</h3>
                <LicenseInfo>Periode: {licensePeriod}</LicenseInfo>
                <LicenseInfo>Startet: {dateInNorwegianTimezone(license.startDate)}</LicenseInfo>
                <LicenseInfo>{(license.type == 'RENEWABLE' && license.autorenewStatus === 'ACTIVE') ? "Fornyes: " : "Utløper: "} {dateInNorwegianTimezone(license.endDate)}</LicenseInfo>
                <LicenseInfo>Antall genereringer: {license.maxGenerations}</LicenseInfo>
                <LicenseInfo>Antall ansatte: {license.maxEmployees}</LicenseInfo>
                {(license.addOns && license.addOns.length > 0) &&
                    <>
                    <h4>Tilleggsavtaler (fornyes ikke)</h4>
                    {license.addOns.map(addOn => {
                      return (
                      <>
                        <LicenseInfo>Startet: {dateInNorwegianTimezone(addOn.startDate)}</LicenseInfo>
                        <LicenseInfo>Utløper: {dateInNorwegianTimezone(addOn.endDate)}</LicenseInfo>
                        <LicenseInfo>Antall ekstra genereringer: {addOn.maxGenerations}</LicenseInfo>
                        <LicenseInfo>Antall ekstra ansatte: {addOn.maxEmployees}</LicenseInfo>
                        <hr></hr>
                        <br />
                      </>);
                    })}
                    </>}
                <br/>
                <LicenseInfo>Antall registrerte ansatte: {license.employeesInDepartment}</LicenseInfo>
                <LicenseInfo>Antall gjenværende ansatte: {license.remainingEmployees}</LicenseInfo>
                <LicenseInfo>Antall kjørte genereringer: {license.generationsInPeriod}</LicenseInfo>
                <LicenseInfo>Antall gjenværende genereringer: {license.remainingGenerations}</LicenseInfo>
                <br/>
                <LicenseInfo><i>Trenger du å oppgradere lisensen din, vennligst kontakt support@dynamon.no</i></LicenseInfo>
              </SettingsContainer>
            </Card>}
          </Tab>
        </Tabs>
      </div>
    </div>
    }
    {displayError && (
      <Modal
        title="Maks antall vakter på rad"
        cancel={() => {

        }}
        cancelButtonProps={{
          style: { display: "none" }
        }}
        done={() => {
          setDisplayError(false);
          updateEmployees();
        }}
        action="Ja, oppdater ansatte"
        secondaryButtonProps={{
          children: 'Nei, avbryt',
          onClick: () => {
            setDisplayError(false);
          }
        }}
      >
        <p>Denne avdelingen har følgende feil:</p>
        {showSavingModal.errorMessages.map((err, i) => <p key={i}>{err}</p>)}
        <p>Vil du oppdatere disse automatisk?</p>
      </Modal>
    )}
    {showSavingModal.display && (
      <Modal
        title="Lagring pågår"
        cancel={() => {

        }}
        cancelButtonProps={{
          style: { display: "none" }
        }}
        primaryButtonProps={{
          disabled: true
        }}
        action="Lukk"
      >
        <ProgressBar value={(100 * showSavingModal.saveCount) / showSavingModal.toSave} />
        <p>{showSavingModal.saveCount}/{showSavingModal.toSave}</p>
      </Modal>
    )}
  </>
  );
};
const LicenseInfo = styled.div`
  font-family: Montserrat Alternates;
  font-size: 16px;
`;
const Select = styled.select`
  border: 1px solid #E5E7f0;
`;

export default Department;
