import {InputRow} from "../lib/styled-components";
import React from "react";
import styled from "styled-components";
import {daySegmentNames, hoursToLocalTime, localTimeToHours, sortShiftCodesByDaySegment} from "../lib/common";


export const ShiftStats = ({task, employees}) => {

    const id = () => (Math.random() + 1).toString(36).substring(2);

    function key(...args) {
        return args.join("-")
    }

    const weeklyCoverDemandsDays = Object.keys(task.config.weeklyCoverDemands).filter(day => day !== "HOLIDAYS");

    const shiftCodes = sortShiftCodesByDaySegment([...new Set(weeklyCoverDemandsDays.flatMap(day => {
        return Object.keys(task.config.weeklyCoverDemands[day]);
    }))], Object.values(task.config.shiftTypes));

    const unusedShiftCodes = sortShiftCodesByDaySegment(Object.keys(task.config.shiftTypes).filter(sh => !shiftCodes.includes(sh)),
        Object.values(task.config.shiftTypes));

    const fromElementsGen = (prefix, codes) => {
        return codes.map(sh =>
            <div key={key(prefix, "fromElementsGenDiv", sh)}>
                <p key={key(prefix, "fromElementsGenP", sh)}><label>{task.config.shiftTypes[sh].coreTimeStart}</label></p>
            </div>
        );
    }

    const toElementsGen = (prefix, codes) => {
        return codes.map(sh => {
            let type = task.config.shiftTypes[sh];
            let start = localTimeToHours(type.coreTimeStart);
            let end = parseFloat(start) + type.shiftHoursMin;
            return <div key={key(prefix, "toElementsGenDiv", sh)}>
                <p key={key(prefix, "toElementsGenP", sh)}><label>{hoursToLocalTime(end)}</label></p>
            </div>
        });
    }

    const fromElements = fromElementsGen("from", shiftCodes);
    const toElements = toElementsGen("to", shiftCodes);
    const unusedFromElements = fromElementsGen("unused-from", unusedShiftCodes);
    const unusedToElements = toElementsGen("unused-to", unusedShiftCodes);

    const days = {
        "MONDAY": "Man",
        "TUESDAY": "Tir",
        "WEDNESDAY": "Ons",
        "THURSDAY": "Tors",
        "FRIDAY": "Fre",
        "SATURDAY": "Lør",
        "SUNDAY": "Søn"
    };

    const weekly = new Map(Object.keys(days).map(day => [day, shiftCodes.map(sh => {
        let val = "0 (0)";
        if(weeklyCoverDemandsDays.includes(day)) {
            if(Object.keys(task.config.weeklyCoverDemands[day]).includes(sh)) {
                val =  task.config.weeklyCoverDemands[day][sh]["minimumNrOfEmployees"] + " (" +
                    task.config.weeklyCoverDemands[day][sh]["maximumNrOfEmployees"] + ")";
            }
        }
        return <p key={key("weekly", "p", sh, day)}><label>{val}</label></p>
    })]));

    const weeklySum = shiftCodes.map(sh => {
        let min = 0;
        let max = 0;
        weeklyCoverDemandsDays.forEach(day => {
            if(Object.keys(task.config.weeklyCoverDemands[day]).includes(sh)) {
                min += parseInt(task.config.weeklyCoverDemands[day][sh]["minimumNrOfEmployees"]);
                max += parseInt(task.config.weeklyCoverDemands[day][sh]["maximumNrOfEmployees"]);
            }
        })
        return <p key={key("weeklySum", "p", sh)}><label>{min + " (" + max + ")"}</label></p>
    })

    const weeklyPerSegment = new Map(Object.keys(days).map(day => {
        let minSum = 0;
        let maxSum = 0;
        let sums = [day, ["D", "A", "N", "L"].map(ds => {
            let min = 0;
            let max = 0;
            Object.keys(task.config.weeklyCoverDemands[day]).forEach(sh => {
                if(task.config.shiftTypes[sh].daySegment === ds) {
                    min += task.config.weeklyCoverDemands[day][sh]["minimumNrOfEmployees"];
                    max += task.config.weeklyCoverDemands[day][sh]["maximumNrOfEmployees"];
                }
            })
            minSum += min;
            maxSum += max;
            return <p key={key("weeklyPerSegment", "p", ds)}><label>{min + " (" + max + ")"}</label></p>;
        })];
        sums[1].push(<p key={key("weeklyPerSegment", "p", "sum")}><label>{minSum + " (" + maxSum + ")"}</label></p>);
        return sums;
    }));

    const weeklySumPerSegment = ["D", "A", "N", "L"].map(ds => {
        let min = 0;
        let max = 0;
        weeklyCoverDemandsDays.forEach(day => {
           Object.keys(task.config.weeklyCoverDemands[day]).forEach(sh => {
               let type = task.config.shiftTypes[sh];
               if(type.daySegment === ds) {
                   min += task.config.weeklyCoverDemands[day][sh]["minimumNrOfEmployees"];
                   max += task.config.weeklyCoverDemands[day][sh]["maximumNrOfEmployees"];
               }
           })
        });
        return {ds: ds, min: min, max: max};
    });

    const weekHours = task.config.fullVacancyRateHours;
    let manYears = 0.0;

    const manYearsPerSegment = ["D", "A", "N", "L"].map(ds => {
        let hours = 0.0;
        weeklyCoverDemandsDays.forEach(day => {
            Object.keys(task.config.weeklyCoverDemands[day]).filter(sh => task.config.shiftTypes[sh].daySegment === ds).forEach(sh => {
                let type = task.config.shiftTypes[sh];
                hours += (type.shiftHoursMin - (parseFloat(type.breakMinutes) / 60)) * parseInt(task.config.weeklyCoverDemands[day][sh].minimumNrOfEmployees);
            })
        });
        const min = (hours/weekHours).toFixed(2);
        manYears += parseFloat(min);
        return <p key={key("manYearsPerSegment", "p", ds)}><label>{min}</label></p>;
    });

    let upperManYears = 0.0;

    const upperManYearsPerSegment = ["D", "A", "N", "L"].map(ds => {
        let maxHours = 0.0;
        weeklyCoverDemandsDays.forEach(day => {
            Object.keys(task.config.weeklyCoverDemands[day]).filter(sh => task.config.shiftTypes[sh].daySegment === ds).forEach(sh => {
                let type = task.config.shiftTypes[sh];
                maxHours += (type.shiftHoursMax - (parseFloat(type.breakMinutes) / 60)) * parseInt(task.config.weeklyCoverDemands[day][sh].maximumNrOfEmployees);
            })
        });
        const max = (maxHours/weekHours).toFixed(2);
        upperManYears += parseFloat(max);
        return <p key={key("upperManYearsPerSegment", "p", ds)}><label>{max}</label></p>;
    })

    const employeeManYears = parseFloat(employees.filter(e => e.enabled)
        .map(e => e.vacancyRate).reduce((acc, curr) => acc + curr, 0) / 100).toFixed(2);

    const empty = (key) => <p key={key}><label>-</label></p>;

    const manYearsSummary = employeeManYears > upperManYears ? +1 : (employeeManYears < manYears ? -1 : 0)

    const traitSummations =
        Object.keys(task.config.traits)
            .reduce((acc, trait) => {
                acc[trait] = Object.keys(days)
                    .reduce((dayAcc, day) => {
                        dayAcc[day] = { D: 0, A: 0, N: 0, L: 0 };
                        Object.entries(task.config.weeklyCoverDemands[day])
                            .forEach(([code, demand]) => {
                                if (demand.traitRequirements[trait]) {
                                    const segment = task.config.shiftTypes[code].daySegment;
                                    dayAcc[day][segment] += parseInt(demand.traitRequirements[trait].value);
                                }
                            });
                        return dayAcc;
                    }, {});

                return acc;
            }, {});

    return (
        <div>
            <label>Vaktkategori sammendrag</label>
            <p></p>
            <InputRow>
                <InputDivider style={{minWidth: "230px"}}>
                    <p><label><b>Vaktkategori</b></label></p>
                    <p><label>Dagvakt</label></p>
                    <p><label>Kveldsvakt</label></p>
                    <p><label>Nattevakt</label></p>
                    <p><label>Langvakt/mellomvakt</label></p>
                    <p><label>Sum</label></p>
                </InputDivider>
                <InputDivider></InputDivider>
                <InputDivider></InputDivider>
                {Object.entries(days).map(([day, trans]) =>
                    <InputDivider key={key("inputDiv", day, "shift", "sum")}>
                        <p key={key("p", day, "shift", "sum")}><label><b>{trans}</b></label></p>
                        {weeklyPerSegment.get(day)}
                    </InputDivider>
                )}
                <InputDivider></InputDivider>
                <InputDivider>
                    <p><label><b>Sum</b></label></p>
                    {weeklySumPerSegment.map(dsSum => <p key={key("p", "weeklySumPerSegmentEl", dsSum.ds)}><label>{dsSum.min + " (" + dsSum.max + ")"}</label></p>)}
                    <Hr ml="-786px" w="850px"></Hr>
                    <p key={key("p", "weeklySumPerSegmentSum")}><label>{weeklySumPerSegment.reduce((acc, obj) => [acc[0] + obj.min, acc[1] + obj.max], [0, 0]).join(' (')})</label></p>
                </InputDivider>
            </InputRow>
            <p></p>
            <label>Spesialkompetanse sammendrag</label>
            <p></p>
            <InputRow>
                <InputDivider style={{minWidth: "230px"}}>
                    <p><label><b>Spesialkompetanse (D/A/N/L)</b></label></p>
                    {Object.entries(task.config.traits).map(([id, name]) => <p key={key(id, name)}><label>{name}</label></p>)}
                </InputDivider>
                <InputDivider></InputDivider>
                <InputDivider></InputDivider>
                {Object.entries(days).map(([day, trans]) =>
                    <InputDivider key={key("trait", "sum", day, "id")}>
                        <p key={key("trait", "sum", day, "p")}><label><b>{trans}</b></label></p>
                        {Object.keys(task.config.traits)
                            .filter(trait => traitSummations[trait])
                            .map(trait => (
                                <p key={key("trait", trait, "sum", day, "p")}><label>{[
                                    traitSummations[trait][day]["D"],
                                    traitSummations[trait][day]["A"],
                                    traitSummations[trait][day]["N"],
                                    traitSummations[trait][day]["L"]].join("/")}</label></p>)
                            )
                        }
                    </InputDivider>
                )}
                <InputDivider></InputDivider>
                <InputDivider>
                    <p><label><b>Sum</b></label></p>
                    {Object.keys(traitSummations).map(trait => {
                        const sums = Object.values(traitSummations[trait]).reduce((acc, sum) => {
                            acc["D"] += sum["D"];
                            acc["A"] += sum["A"];
                            acc["N"] += sum["N"];
                            acc["L"] += sum["L"];
                            return acc;
                        }, {D: 0, A: 0, N: 0, L: 0});
                        return (<p key={key(trait, "sum", "p")}><label>{[sums["D"], sums["A"], sums["N"], sums["L"]].join("/")}</label></p>)
                    })}
                </InputDivider>
            </InputRow>
            <p></p>
            <label>Årsverk (ferie og helligdager ikke inkludert)</label>
            <p></p>
            <InputRow>
                <InputDivider style={{minWidth: "230px"}}>
                    <p><label><b>Vaktkategori</b></label></p>
                    <p><label>Dagvakt</label></p>
                    <p><label>Kveldsvakt</label></p>
                    <p><label>Nattevakt</label></p>
                    <p><label>Langvakt/mellomvakt</label></p>
                    <p><label>Sum</label></p>
                </InputDivider>
                <InputDivider>
                    <p><label><b>Minimumsbehov</b></label></p>
                    {manYearsPerSegment}
                    <p><label>{manYears.toFixed(2)}</label></p>
                </InputDivider>
                <InputDivider></InputDivider>
                <InputDivider>
                    <p><label><b>Maksimumsbehov</b></label></p>
                    {upperManYearsPerSegment}
                    <p><label>{upperManYears.toFixed(2)}</label></p>
                </InputDivider>
                <InputDivider></InputDivider>
                <InputDivider>
                    <p><label><b>Tilgjengelig årsverk</b></label></p>
                    {Array.from({length: 4}, (_, i) => empty(key(i, "manyears")))}
                    <p><label>{employeeManYears}</label></p>
                </InputDivider>
                <InputDivider></InputDivider>
                <InputDivider>
                    <p><label><b>Oppsummering</b></label></p>
                    {Array.from({length: 4}, (_, i) => empty(key(i, "sum")))}
                    <Hr ml="-795px" w="940px"></Hr>
                    <p style={{color: manYearsSummary > 0 ? "blue" : (manYearsSummary < 0 ? "red": "green")}}>
                        <label>{manYearsSummary > 0 ? "OVERBEMANNET" : (manYearsSummary < 0 ? "UNDERBEMANNET": "BALANSE")}</label>
                    </p>
                </InputDivider>
            </InputRow>
            <p></p>
            <label>Vaktkode sammendrag</label>
            <p></p>
            <InputRow>
                <InputDivider>
                    <p><label><b>Kode</b></label></p>
                    {shiftCodes.map(sh =>
                        <div key={key("div", id(), sh)}>
                            <p key={key("p", id(), sh)}><label>{sh}</label></p>
                        </div>
                    )}
                </InputDivider>
                <InputDivider>
                    <p><label><b>Kjernetid start</b></label></p>
                    {fromElements}
                </InputDivider>
                <InputDivider>
                    <p><label><b>Kjernetid slutt</b></label></p>
                    {toElements}
                </InputDivider>
                <InputDivider></InputDivider>
                {Object.entries(days).map(([day, trans]) =>
                    <InputDivider key={key(day, "id", "code")}>
                        <p key={key("p", day, "code")}><label><b>{trans}</b></label></p>
                        {weekly.get(day)}
                    </InputDivider>
                )}
                <InputDivider></InputDivider>
                <InputDivider>
                    <p><label><b>Sum</b></label></p>
                    {weeklySum}
                </InputDivider>
            </InputRow>
            <p></p>
            <label>Ubrukte vaktkoder</label>
            <p></p>
            <InputRow>
                <InputDivider>
                    <p><label><b>Kode</b></label></p>
                    {unusedShiftCodes.map(sh =>
                        <div key={key(sh, "unused", "div")}>
                            <p key={key(sh, "unused", "p")}><label>{sh}</label></p>
                        </div>
                    )}
                </InputDivider>
                <InputDivider>
                    <p><label><b>Vaktkategori</b></label></p>
                    {unusedShiftCodes.map(sh =>
                        <div key={key(sh, "unused", "div", "shift", "type")}>
                            <p key={key(sh, "unused", "p", "shift", "type")}><label>{daySegmentNames[task.config.shiftTypes[sh].daySegment]}</label></p>
                        </div>
                    )}
                </InputDivider>
                <InputDivider>
                    <p><label><b>Kjernetid start</b></label></p>
                    {unusedFromElements}
                </InputDivider>
                <InputDivider>
                    <p><label><b>Kjernetid slutt</b></label></p>
                    {unusedToElements}
                </InputDivider>
            </InputRow>
            <p></p>
        </div>
    );
}
const InputDivider = styled.div`
  min-width: 70px;
  margin-bottom: 10px;
`
const Hr = styled.hr`
    width: ${props => props.w};
    margin-left: ${props => props.ml};
    margin-top: -5px;
    position: absolute;
`;