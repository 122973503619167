import React, { useState} from "react";
import {Button, Modal, Alert} from "rendition";
import styled from "styled-components";
import ErrorMessagePopover from "./error-message-popover";
import {
  InputWithValidation,
  CardHeader,
  CardLetter,
  CardLetterBox,
  NumberInput,
  CardTimeMessage,
  StyledInputMask
} from "../lib/styled-components";
import {
  noDecimalRegex,
  localTimeToHours,
  isTaskReadOnly,
  norwegianDays, emptyDemand, getDaySegment, hoursToLocalTime, withShiftDefinition
} from "../lib/common";
import Tooltip from "../lib/tooltip";
import { CompetenceInputs, PositionInputs } from "./task-components";
import Icon from "../lib/icon";
import {applyPatch} from "rfc6902";

export const DayShift = ({
  register,
  unregister,
  task,
  day,
  shift,
  errors,
  positions,
  traitNames,
  watch,
  highlightedInputs = [],
  custom = false,
  dailyCoverPatches,
  deletePatch,
  doPatch,
  setShift,
  date,
  setValue,
  removeShiftType
}) => {
  const [showDeleteShiftModal, setShowDeleteShiftModal] = useState(false);
  const handleCustomAddShift = (sh) => {
    let daily = JSON.parse(JSON.stringify(task.config.weeklyCoverDemands[day]));
    let shType = task.config.shiftTypes[sh];
    applyPatch(daily, JSON.parse(JSON.stringify(dailyCoverPatches[date])));
    daily[sh].shiftDefinition = {
      'start': shType.start,
      'coreTimeStart': shType.coreTimeStart,
      'shiftHoursMax': shType.shiftHoursMax,
      'shiftHoursMin': shType.shiftHoursMin,
      'coreTimeEnd': hoursToLocalTime(parseFloat(localTimeToHours(shType.coreTimeStart)) + parseFloat(shType.shiftHoursMin)),
      'end': hoursToLocalTime(parseFloat(localTimeToHours(shType.start)) + parseFloat(shType.shiftHoursMax))
    };
    return daily[sh];
  }

  const shiftData = Object.keys(task.config.weeklyCoverDemands[day]).includes(shift)
      ? task.config.weeklyCoverDemands[day][shift]
      : (!custom ? withShiftDefinition(emptyDemand(task.config.shiftTypes[shift].daySegment)) : handleCustomAddShift(shift));

  const shiftDefinition = shiftData.shiftDefinition;

  const InputError = (day, errors, shift) => {
    switch (day) {
      case "MONDAY": return ShiftError(errors?.config?.weeklyCoverDemands?.MONDAY, shift);
      case "TUESDAY": return ShiftError(errors?.config?.weeklyCoverDemands?.TUESDAY, shift);
      case "WEDNESDAY": return ShiftError(errors?.config?.weeklyCoverDemands?.WEDNESDAY, shift);
      case "THURSDAY": return ShiftError(errors?.config?.weeklyCoverDemands?.THURSDAY, shift);
      case "FRIDAY": return ShiftError(errors?.config?.weeklyCoverDemands?.FRIDAY, shift);
      case "SATURDAY": return ShiftError(errors?.config?.weeklyCoverDemands?.SATURDAY, shift);
      case "SUNDAY": return ShiftError(errors?.config?.weeklyCoverDemands?.SUNDAY, shift);
      case "HOLIDAYS": return ShiftError(errors?.config?.weeklyCoverDemands?.HOLIDAYS, shift);
    }
  }

  const getOneCoverErrorMessage = (errorMessages) => {
    if (errorMessages[0]) return errorMessages[0];
  }

  const shiftDescription = () => {
    let base = task.config.shiftTypes[shift].daySegment;
    return base === "D" ? "Dagvakt" : base === "L" ? "Langvakt/Mellomvakt" : base === "A" ? "Kveldsvakt" : "Nattevakt"
  }

  let minNrOfEmpls = () => { return parseInt(watch(`config.weeklyCoverDemands.${day}.${shift}.minimumNrOfEmployees`)); };
  let minNrOfResp = () => { return parseInt(watch(`config.weeklyCoverDemands.${day}.${shift}.minimumResponsibleEmployees`)); };
  let maxNrOfEmpls = () => { return parseInt(watch(`config.weeklyCoverDemands.${day}.${shift}.maximumNrOfEmployees`)); }

  const PositionHeaders = () => {
    return positions?.map((position) => {
      return <InputDivider key={`ph-${position.id}`}>
        <InputDivider data-for={`h-${position.name}`} data-tip="Spesifiser minimum antall ansatte du ønsker at skal starte på dette tidspunktet"><p>{position.name}</p></InputDivider>
        <Tooltip id={`h-${position.name}`} />
      </InputDivider>
    });
  }

  const checkLengthAndAdjust = (el, maxLength) => {
    let str = watch(el);
    let nr = parseInt(str);
    if(isNaN(nr)) {
      return;
    } else if(nr > maxLength) {
      setValue(el, maxLength);
    } else if(nr < 0) {
      setValue(el, 0);
    }
  }

  const ShiftError = (error, shift) => {
    if(error && Object.keys(error).includes(shift)) {
      return error[shift]
    }
  }

  return (
    <div>
      <CardLetterBox>
        <CardLetter>{shift}</CardLetter>
      </CardLetterBox>
      {!isTaskReadOnly(task) && <Button danger style={{marginRight: "20px", float: "right", marginTop: "10px"}}
              onClick={() => setShowDeleteShiftModal(true)}>
        <Icon name="trash-w"/>
      </Button>}
      {showDeleteShiftModal &&
          <Modal
              action="OK"
              done={() => {
                if(!custom) {
                  delete task.config.weeklyCoverDemands[day][shift];
                  unregister(`config.weeklyCoverDemands.${day}.${shift}`);
                  doPatch([{
                    op: "remove",
                    path: `/config/weeklyCoverDemands/${day}/${shift}`
                  }], "Vaktkode fjernet");
                  setShift(null);
                } else {
                  removeShiftType(shift);
                }
                setShowDeleteShiftModal(false);
              }}
              cancel={() => {
                setShowDeleteShiftModal(false);
              }}
              cancelButtonProps={{
                style: { display: "none" }
              }}
              secondaryButtonProps={{
                children: 'Avbryt',
                onClick: () => setShowDeleteShiftModal(false)
              }}
          ><h3><Alert plaintext danger style={{ whiteSpace: "nowrap"}}>Fjerne</Alert></h3>
            <p style={{fontSize: "17px"}}>Er du sikker på at du ønsker å fjerne vaktkode <i>{shift}</i> fra <i>{norwegianDays(day)}</i>
              {custom ? " i denne uken" : ""} i bemanningsplanen?</p>
          </Modal>
      }
      <CardTimeMessage>{getDaySegment(task, shift) === "N" ? "*Start tidspunkt og kjernetiden start må være før midnatt" : ""}</CardTimeMessage>
      <ShiftCard style={{backgroundColor: custom && !Object.keys(task.config.weeklyCoverDemands[day]).includes(shift) ? "#e4f8e0": ""}}>
        <CardHeader>{shiftDescription()}</CardHeader>
        <InputRow>
          <InputDivider style={{ marginBottom: "35px" }}>
            <p><label htmlFor={`config.weeklyCoverDemands.${day}.${shift}.shiftDefinition.start`}>Starter:</label></p>
            <InputWithValidation width="190px">
              <StyledInputMask
                readOnly={true}
                style={{backgroundColor: "lightgrey"}}
                id={`config.weeklyCoverDemands.${day}.${shift}.shiftDefinition.start`}
                key={`config.weeklyCoverDemands.${day}.${shift}.shiftDefinition.start`}
                value={shiftDefinition.start ? shiftDefinition.start : "07:00"}
                className={highlightedInputs.includes(`/${shift}/shiftDefinition/start`) ? isTaskReadOnly(task) ? "input-highlight-read-only" : "input-highlight"  : ""}
                {...register(`config.weeklyCoverDemands.${day}.${shift}.shiftDefinition.start`)}
                mask={[/^([0-2])/, /([0-9])/, ":", /[0-5]/, /[0-9]/]}
                maskPlaceholder="-"
                alwaysShowMask={true}
                data-for="shiftDefinition.start"
                data-tip="Her angir du når vakten skal starte - når skal første ansatt komme på vakt?"
              />
            </InputWithValidation>
            <Tooltip id="shiftDefinition.start" />
          </InputDivider>
          <InputDivider style={{ marginBottom: "35px" }}>
            <p><label htmlFor={`config.weeklyCoverDemands.${day}.${shift}.shiftDefinition.coreTimeStart`}>Kjernetid start klokken:</label></p>
            <InputWithValidation width="190px">
              <StyledInputMask
                readOnly={true}
                style={{backgroundColor: "lightgrey"}}
                id={`config.weeklyCoverDemands.${day}.${shift}.shiftDefinition.coreTimeStart`}
                key={`config.weeklyCoverDemands.${day}.${shift}.shiftDefinition.coreTimeStart`}
                value={shiftDefinition.coreTimeStart ? shiftDefinition.coreTimeStart : "09:00"}
                className={highlightedInputs.includes(`/${shift}/shiftDefinition/coreTimeStart`) ? isTaskReadOnly(task) ? "input-highlight-read-only" : "input-highlight"  : ""}
                mask={[/^([0-2])/, /([0-9])/, ":", /[0-5]/, /[0-9]/]}
                maskPlaceholder="-"
                alwaysShowMask={true}
                {...register(`config.weeklyCoverDemands.${day}.${shift}.shiftDefinition.coreTimeStart`)}
                data-for="shiftDefinition.coreTimeStart"
                data-tip="Når skal siste ansatte komme på vakt - når skal alle ansatte være i avdeling fra?"
              />
            </InputWithValidation>
            <Tooltip id="shiftDefinition.coreTimeStart" />
          </InputDivider>
          <InputDivider style={{ marginBottom: "35px" }}>
            <p><label htmlFor={`config.weeklyCoverDemands.${day}.${shift}.shiftDefinition.coreTimeEnd`}>Kjernetid slutt klokken:</label></p>
            <InputWithValidation width="190px">
              <StyledInputMask
                readOnly={true}
                style={{backgroundColor: "lightgrey"}}
                id={`config.weeklyCoverDemands.${day}.${shift}.shiftDefinition.coreTimeEnd`}
                key={`config.weeklyCoverDemands.${day}.${shift}.shiftDefinition.coreTimeEnd`}
                value={shiftDefinition.coreTimeEnd ? shiftDefinition.coreTimeEnd : "13:00"}
                className={highlightedInputs.includes(`/${shift}/shiftDefinition/coreTimeEnd`) ? isTaskReadOnly(task) ? "input-highlight-read-only" : "input-highlight"  : ""}
                mask={[/^([0-2])/, /([0-9])/, ":", /[0-5]/, /[0-9]/]}
                maskPlaceholder="-"
                alwaysShowMask={true}
                {...register(`config.weeklyCoverDemands.${day}.${shift}.shiftDefinition.coreTimeEnd`)}
                data-for="shiftDefinition.coreTimeEnd"
                data-tip="Når kan første ansatte forlate vakten?"
              />
              {ErrorMessagePopover(InputError(day, errors, shift)?.shiftDefinition?.coreTimeEnd, 'required', 'Kjernetid slutt er påkrevd')}
              {ErrorMessagePopover(InputError(day, errors, shift)?.shiftDefinition?.coreTimeEnd, 'pattern', 'Klokkeslett må være mellom 00:00 og 23:59')}
              {ErrorMessagePopover(InputError(day, errors, shift)?.shiftDefinition?.coreTimeEnd, 'validate', '“Kjernetid slutt klokken” må være et tidspunkt mellom “kjernetid start klokken” og når vakten “Slutter”')}
            </InputWithValidation>
            <Tooltip id="shiftDefinition.coreTimeEnd" />
          </InputDivider>
          <InputDivider style={{ marginBottom: "35px" }}>
            <p><label htmlFor={`config.weeklyCoverDemands.${day}.${shift}.shiftDefinition.end`}>Slutter:</label></p>
            <InputWithValidation width="190px">
              <StyledInputMask
                readOnly={true}
                style={{backgroundColor: "lightgrey"}}
                id={`config.weeklyCoverDemands.${day}.${shift}.shiftDefinition.end`}
                key={`config.weeklyCoverDemands.${day}.${shift}.shiftDefinition.end`}
                value={shiftDefinition.end ? shiftDefinition.end : "15:00"}
                className={highlightedInputs.includes(`/${shift}/shiftDefinition/end`) ? isTaskReadOnly(task) ? "input-highlight-read-only" : "input-highlight"  : ""}
                mask={[/^([0-2])/, /([0-9])/, ":", /[0-5]/, /[0-9]/]}
                maskPlaceholder="-"
                alwaysShowMask={true}
                {...register(`config.weeklyCoverDemands.${day}.${shift}.shiftDefinition.end`)}
                data-for="shiftDefinition.end"
                data-tip="Her angir du når vakten skal slutte - når skal siste ansatt forlate vakten?"
              />
              {ErrorMessagePopover(InputError(day, errors, shift)?.shiftDefinition?.end, 'required', 'Slutt-tid er påkrevd')}
              {ErrorMessagePopover(InputError(day, errors, shift)?.shiftDefinition?.end, 'pattern', 'Klokkeslett må være mellom 00:00 og 23:59')}
              {ErrorMessagePopover(InputError(day, errors, shift)?.shiftDefinition?.end, 'validate', 'Sluttid må være etter starttid, kjernetidstart, kjernetidslutt og sluttidspunkt (bemanning på slutten av vakten)')}
            </InputWithValidation>
            <Tooltip id="shiftDefinition.end" />
          </InputDivider>
            <InputDivider style={{ marginBottom: "35px" }}>
              <p><label htmlFor={"minimumNrOfEmployees"}>Minimum antall ansatte</label></p>
              <InputWithValidation width="190px">
                <NumberInput
                    id={`config.weeklyCoverDemands.${day}.${shift}.minimumNrOfEmployees`}
                    key={`config.weeklyCoverDemands.${day}.${shift}.minimumNrOfEmployees`}
                    placeholder=""
                    onChange={checkLengthAndAdjust(`config.weeklyCoverDemands.${day}.${shift}.minimumNrOfEmployees`, 999)}
                    type="number"
                    width="80px"
                    defaultValue={shiftData ? shiftData.minimumNrOfEmployees : "15"}
                    className={highlightedInputs.includes(`/${shift}/minimumNrOfEmployees`) ? isTaskReadOnly(task) ? "input-highlight-read-only" : "input-highlight"  : ""}
                    {...register(`config.weeklyCoverDemands.${day}.${shift}.minimumNrOfEmployees`, {
                      required: true, valueAsNumber: true, min: 0, pattern: noDecimalRegex(), validate: () => {
                        return minNrOfEmpls() <= maxNrOfEmpls();
                      }
                    })}
                    data-for="minimumNrOfEmployees"
                    data-tip="Her angir du minimum antall ansatte som du trenger på denne vakten"
                />
                {ErrorMessagePopover(InputError(day, errors, shift)?.minimumNrOfEmployees, 'required', 'Minimum antall må ha en tallverdi')}
                {ErrorMessagePopover(InputError(day, errors, shift)?.minimumNrOfEmployees, 'min', 'Minimum antall kan ikke være negative tall')}
                {ErrorMessagePopover(InputError(day, errors, shift)?.minimumNrOfEmployees, 'pattern', 'Desimaltall er ikke tillatt')}
                {ErrorMessagePopover(InputError(day, errors, shift)?.minimumNrOfEmployees, 'validate', 'Må være lavere enn maksimum antall ansatte')}
                <div style={{ display: !highlightedInputs.includes(`/${shift}/minimumNrOfEmployees`) ? "none" : "", position: "absolute", float: "right", width: "120px", paddingTop: "2px", pointerEvents: "none" }}>
                  <Button className="deletePatchButton" style={{ float: "right", pointerEvents: "all" }} ml="auto" plain onClick={() => deletePatch(`${day}.${shift}.minimumNrOfEmployees`)}><img src="/assets/icons/roll.png" /></Button>
                </div>
              </InputWithValidation>
              <Tooltip id="minimumNrOfEmployees" />
            </InputDivider>

            <InputDivider style={{ marginBottom: "35px" }}>
              <p><label htmlFor={"maximumNrOfEmployees"}>Maksimum antall ansatte </label></p>
              <InputWithValidation width="230px">
                <NumberInput
                    id={`config.weeklyCoverDemands.${day}.${shift}.maximumNrOfEmployees`}
                    key={`config.weeklyCoverDemands.${day}.${shift}.maximumNrOfEmployees`}
                    placeholder=""
                    type="number"
                    onChange={checkLengthAndAdjust(`config.weeklyCoverDemands.${day}.${shift}.maximumNrOfEmployees`, 999)}
                    width="80px"
                    defaultValue={shiftData ? shiftData.maximumNrOfEmployees : "20"}
                    className={highlightedInputs.includes(`/${shift}/maximumNrOfEmployees`) ? isTaskReadOnly(task) ? "input-highlight-read-only" : "input-highlight"  : ""}
                    {...register(`config.weeklyCoverDemands.${day}.${shift}.maximumNrOfEmployees`, {
                      required: true, valueAsNumber: true, min: 0, pattern: noDecimalRegex(), validate: () => {
                        return true;
                      }
                    })}
                    data-for="maximumNrOfEmployees"
                    data-tip="Her angir du maksimum antall ansatte som du trenger på denne vakten"
                />
                {ErrorMessagePopover(InputError(day, errors, shift)?.maximumNrOfEmployees, 'required', 'Maksimum antall må ha en tallverdi')}
                {ErrorMessagePopover(InputError(day, errors, shift)?.maximumNrOfEmployees, 'min', 'Maksimum antall kan ikke være negative tall')}
                {ErrorMessagePopover(InputError(day, errors, shift)?.maximumNrOfEmployees, 'pattern', 'Desimaltall er ikke tillatt')}
                <div style={{ display: !highlightedInputs.includes(`/${shift}/maximumNrOfEmployees`) ? "none" : "", position: "absolute", float: "right", width: "120px", paddingTop: "2px", pointerEvents: "none" }}>
                  <Button className="deletePatchButton" style={{ float: "right", pointerEvents: "all" }} ml="auto" plain onClick={() => deletePatch(`${day}.${shift}.maximumNrOfEmployees`)}><img src="/assets/icons/roll.png" /></Button>
                </div>
              </InputWithValidation>
              <Tooltip id="maximumNrOfEmployees" />
            </InputDivider>
          </InputRow>

          <InputRow style={{ marginTop: "25px" }}>
            <InputDivider>
              <p><label htmlFor={"minimumResponsibleEmployees"}>Ansvarsvakt</label></p>
              <InputWithValidation width="190px">
                <NumberInput
                    id={`config.weeklyCoverDemands.${day}.${shift}.minimumResponsibleEmployees`}
                    key={`config.weeklyCoverDemands.${day}.${shift}.minimumResponsibleEmployees`}
                    placeholder=""
                    type="number"
                    onChange={checkLengthAndAdjust(`config.weeklyCoverDemands.${day}.${shift}.minimumResponsibleEmployees`, 999)}
                    width="80px"
                    defaultValue={shiftData ? shiftData.minimumResponsibleEmployees : "2"}
                    className={highlightedInputs.includes(`/${shift}/minimumResponsibleEmployees`) ? isTaskReadOnly(task) ? "input-highlight-read-only" : "input-highlight"  : ""}
                    {...register(`config.weeklyCoverDemands.${day}.${shift}.minimumResponsibleEmployees`, {
                      required: true, valueAsNumber: true, min: 0, pattern: noDecimalRegex(), validate: () => {
                        return minNrOfResp() <= maxNrOfEmpls();
                      }
                    })}
                    data-for="minimumResponsibleEmployees"
                    data-tip="Her angir du antall ansvarsvakter du trenger på denne vakten"
                />
                {ErrorMessagePopover(InputError(day, errors, shift)?.minimumResponsibleEmployees, 'required', 'Antall ansvarlige må ha en tallverdi')}
                {ErrorMessagePopover(InputError(day, errors, shift)?.minimumResponsibleEmployees, 'min', 'Antall ansvarlige kan ikke være negative tall')}
                {ErrorMessagePopover(InputError(day, errors, shift)?.minimumResponsibleEmployees, 'pattern', 'Desimaltall er ikke tillatt')}
                {ErrorMessagePopover(InputError(day, errors, shift)?.minimumResponsibleEmployees, 'validate', 'Antall ansvarsvakter kan ikke overskride maks antall ansatte')}
                <div style={{ display: !highlightedInputs.includes(`/${shift}/minimumResponsibleEmployees`) ? "none" : "", position: "absolute", float: "right", width: "120px", paddingTop: "2px", pointerEvents: "none" }}>
                  <Button className="deletePatchButton" style={{ float: "right", pointerEvents: "all" }} ml="auto" plain onClick={() => deletePatch(`${day}.${shift}.minimumResponsibleEmployees`)}><img src="/assets/icons/roll.png" /></Button>
                </div>
              </InputWithValidation>
              <Tooltip id="minimumResponsibleEmployees" />
            </InputDivider>

            <CompetenceInputs traitNames={traitNames} register={register} InputError={InputError} day={day} shift={shift}
                              shiftData={shiftData} watch={watch} maxNrOfEmpls={maxNrOfEmpls} errors={errors}
                              highlightedInputs={highlightedInputs} deletePatch={deletePatch} task={task}
                              checkLengthAndAdjust={checkLengthAndAdjust}/>
          </InputRow>
        </ShiftCard>

        <ShiftCard style={{backgroundColor: custom && !Object.keys(task.config.weeklyCoverDemands[day]).includes(shift) ? "#e4f8e0": ""}}>
          <h3 data-for="core" data-tip="Her spesifiserer du antall pr kompetanse du ønsker å ha tilstede i kjernetiden - den tiden der alle ansatte skal være på vakt">Ansatte på jobb i kjernetiden</h3>
          <Tooltip id="core" />
          <TimeCover>
            <InputDivider><p>Tid</p></InputDivider>
            <PositionHeaders />
          </TimeCover>
          <PositionInputs shiftData={shiftData} positions={positions} register={register} InputError={InputError}
                          day={day} shift={shift} errors={errors} getOneCoverErrorMessage={getOneCoverErrorMessage}
                          maxNrOfEmpls={maxNrOfEmpls} watch={watch} highlightedInputs={highlightedInputs} deletePatch={deletePatch}
                          task={task} checkLengthAndAdjust={checkLengthAndAdjust}/>
        </ShiftCard>
      </div>
  );
};

const InputRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  column-gap: 2em;
  row-gap: 2em;
  flex-wrap: wrap;
`;

const InputDivider = styled.div`
  min-width: 190px;
  margin-bottom: 10px;
`;

const ShiftCard = styled.div`
  background-color: #FFF;
  border-radius: 10px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
  margin: 15px 0px;
  margin-bottom: 25px;
  padding: 1px 25px 25px 25px;
  width: 1350px;
`;

const TimeCover = styled(InputRow)`
  border-bottom: 1px solid #DDD;
  padding-bottom: 5px;
  margin-bottom: 5px;
`;
