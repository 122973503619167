import React, {useContext, useEffect, useState} from "react";
import {Box, Divider, Flex, Input, Txt, Button, ButtonGroup} from "rendition";
import Accordion from "../accordion";
import BlockedPatternsPickers from "./blocked-patterns-picker";
import DatePicker from "react-datepicker";
import Select from "react-select";
import moment from "moment/moment";
import Icon from "../../lib/icon";
import Tooltip from "../../lib/tooltip";
import dynomiteContext from "../dynomite";
import {
    getDaysFromDatePattern,
    getDaysFromDatePatterns,
    rollupAndReduce,
    fullSchemeYlOrRd,
    monthsShort
} from "../../lib/common";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';
import CalendarHeatmap from "../vis-components/calendar-heatmap";

/**
 * Component to show all blocked patterns of all employees and their vacations.
 * 
 * @param {string} departmentId - Id of the department
 * @param {Employee[]} employees - List of employees
 * @param {function} setEmployees - Callback to update the list of employees
 * @param {Position[]} positions - List of positions
 * @param {Trait[]} traits - List of traits
 * @param {function} reset - Callback to reset the component
 */
export default ({departmentId, employees, setEmployees, positions, traits, reset}) => {
    const dynomite = useContext(dynomiteContext);
    const [searchFilters, setSearchFilters] = useState({
        employee: undefined,
        position: undefined,
        traits: [],
        startDate: undefined,
        endDate: undefined
    });
    const [filteredEmployees, setFilteredEmployees] = useState(employees);
    const [visKinds, setVisKinds] = useState(["not-working", "vacation"]);
    const [visDaySegments, setVisDaySegments] = useState(["d", "a", "l", "n"]);
    const [visYear, setVisYear] = useState(new Date().getFullYear());
    // don't like it, but don't feel like creating yet another component either
    const [tmpShowCalendar, setTmpShowCalendar] = useState(false);
    const employeesCount = employees.length;

    /**
     * Reset the component at the initial load.
     */
    useEffect(() => {return () => { reset();}}, []);

    /**
     * Adjust filtered employees (and their date patterns) based on the change
     * of filter parameters.
     */
    useEffect(() => {
        setFilteredEmployees(
            employees
                .filter(e => 
                    !searchFilters.employee
                    || e.name.toLowerCase().includes(searchFilters.employee.toLowerCase())
                )
                .filter(e =>
                    !searchFilters.position
                    || searchFilters.position === e.position
                )
                .filter(e =>
                    searchFilters.traits.length === 0
                    ||  searchFilters.traits.some(t => e.traits.includes(t))
                )
                .reduce(
                    (acc, e) => {
                        const blocked = (e.blockedPatterns || [])
                            .filter(p =>
                                (!searchFilters.startDate && !searchFilters.endDate)
                                || getDaysFromDatePattern(
                                        dynomite,
                                        {...p, disabled: false},
                                        searchFilters.startDate,
                                        searchFilters.endDate || moment(`${visYear}-12-31`)
                                ).length > 0
                            )
                        const vacationPatterns = (e.vacationPatterns || [])
                            .filter(p =>
                                (!searchFilters.startDate && !searchFilters.endDate)
                                || getDaysFromDatePattern(
                                    dynomite,
                                    {...p, disabled: false},
                                    searchFilters.startDate,
                                    searchFilters.endDate || moment(`${visYear}-12-31`)
                                ).length > 0)
                        if ((!searchFilters.startDate && !searchFilters.endDate)
                            || (blocked.length > 0 || vacationPatterns.length > 0)) {
                            acc.push({
                                ...e,
                                blockedPatterns: blocked,
                                vacationPatterns: vacationPatterns
                            })
                        }
                        return acc
                    },
                    []
                )
        );
    }, [searchFilters]);

    /**
     * Toggle to follow the typical functionality of chackboxgroup.
     * If item is not present, add it. If item already exist, remove it.
     * All in context of datePattern day segments.
     * @param {string} item
     * @returns {*}
     */
    const toggleDaySegment = item => visDaySegments.includes(item) ?
        setVisDaySegments(visDaySegments.filter(el => el !== item)) :
        setVisDaySegments([...visDaySegments, item]);
    
    /**
     * Toggle to follow the typical functionality of chackboxgroup.
     * If item is not present, add it. If item already exist, remove it.
     * All in context of datePattern kinds.
     * @param {string} item
     * @returns {*}
     */
    const toggleKind = item => visKinds.includes(item) ?
        setVisKinds(visKinds.filter(el => el !== item)) :
        setVisKinds([...visKinds, item]);

    return (
        <>
            <Flex
                justifyContent="space-between"
                style={{ gap: "0.5em" }}
            >
                <Box style={{flex: "2 1 160px"}}>
                    <Txt style={{ "marginBottom": "1em" }}>Søk etter ansatte</Txt>
                    <Input
                        style={{borderColor: "#ccc"}}
                        value={searchFilters.employee}
                        placeholder=""
                        onChange={e => setSearchFilters({
                            ...searchFilters,
                            employee: e.target.value
                        })}
                    />
                </Box>
                <Box style={{flex: "1 1 160px"}}>
                    <Txt style={{ "marginBottom": "1em" }}>Filter på stilling</Txt>
                    <Select
                        placeholder=""
                        isClearable={true}
                        isMulti={false}
                        options={positions.map(p => ({
                            label: p.name,
                            value: p.id
                        }))}
                        theme={(theme) => ({
                            ...theme,
                            colors: {
                                ...theme.colors,
                                primary: "#306970",
                                primary75: "#8ea1a4",
                                primary50: "bec7c9",
                                primary25: "#dfe8e9",
                            }
                        })}
                        style={{width: "160px"}}
                        styles={{
                            control: (provided, state) => ({
                                ...provided,
                                width: '160px',
                                boxShadow: state.isFocused ? "0 0 2px 2px #306970" : null
                            }),
                            menu: (provided) => ({
                                ...provided,
                                width: '160px'
                            })
                        }}
                        onChange={pos => {
                            setSearchFilters({
                                ...searchFilters,
                                position: pos ? pos.value : undefined
                            })
                        }}
                    />
                </Box>
                <Box style={{flex: "1 1 160px"}}>
                    <Txt style={{ "marginBottom": "1em" }}>Filter på kompetanse</Txt>
                    <Select
                        placeholder=""
                        isClearable={true}
                        isMulti={true}
                        options={traits.map(p => ({
                            label: p.name,
                            value: p.id
                        }))}
                        theme={(theme) => ({
                            ...theme,
                            colors: {
                                ...theme.colors,
                                primary: "#306970",
                                primary75: "#8ea1a4",
                                primary50: "#bec7c9",
                                primary25: "#dfe8e9",
                            }
                        })}
                        style={{width: "160px"}}
                        styles={{
                            control: (provided, state) => ({
                                ...provided,
                                boxShadow: state.isFocused ? "0 0 2px 2px #306970" : null,
                                width: '160px'
                            }),
                            menu: (provided) => ({
                                ...provided,
                                width: '160px'
                            })
                        }}
                        onChange={(traits) => setSearchFilters({
                            ...searchFilters,
                            traits: traits.map(t => t.value)
                        })}
                    />
                </Box>
                <Box style={{flex: "0 0 300px"}}>
                    <Txt style={{ "marginBottom": "1em" }}
                        data-for="pattern-start-end"
                        data-tip="Velg perioden du ønsker å se fravær i kalenderen, og ansattlisten under viser hvilke ansatte som er berørt."
                    >Filtrer etter datoer</Txt>
                    <Tooltip id="pattern-start-end" />
                    <Flex
                        justifyContent="space-between"
                        flexDirection={"row"}
                        style={{
                            gap: "1em",
                            padding: "10px 10px 5px 10px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                            boxShadow: tmpShowCalendar ? "0 0 2px 2px #306970" : null
                        }}
                    >
                        <Box style={{width: "20px"}}>
                            <DatePicker
                                showPopperArrow={false}
                                showWeekNumbers
                                locale="nb"
                                dateFormat="yyyy-MM-dd"
                                selected={searchFilters.endDate || searchFilters.startDate}
                                startDate={searchFilters.startDate}
                                endDate={searchFilters.endDate}
                                maxDate={moment().add(10, "years").toDate()}
                                selectsRange
                                shouldCloseOnSelect={false}
                                onChange={dates => setSearchFilters({
                                        ...searchFilters,
                                        startDate: dates[0],
                                        endDate: dates[1]
                                    })
                                }
                                onCalendarOpen={() => setTmpShowCalendar(true)}
                                onCalendarClose={() => setTmpShowCalendar(false)}
                                customInput={
                                    <Flex
                                        style={{
                                            alignItems: "center",
                                            cursor: "pointer",
                                            width: "20px",
                                        }}>
                                        <Icon name="date" f="right" />
                                    </Flex>
                                }
                            ></DatePicker>
                        </Box>
                        <Box style={{width: "110px"}}>
                            Fra: {searchFilters.startDate ?
                                moment(searchFilters.startDate).format("DD.MM.YYYY")
                                : "dd.mm.åååå"}
                        </Box>
                        <Box style={{width: "110px"}}>
                            Til: {searchFilters.endDate ?
                                moment(searchFilters.endDate).format("DD.MM.YYYY")
                                : "dd.mm.åååå"}
                        </Box>
                        <Button
                            plain
                            style={{
                                alignItems: "start",
                                paddingTop: "3px",
                            }}
                            onClick={() => setSearchFilters({
                                ...searchFilters,
                                startDate: undefined,
                                endDate: undefined
                            })}
                        ><FontAwesomeIcon icon={faTimes} /></Button>
                    </Flex>
                </Box>
                <Box style={{flex: "0 0 140px"}}>
                    <Txt style={{ "marginBottom": "1em" }}
                        data-for="patterns-vis-type"
                        data-tip="Knappene aktiverer og deaktiverer visning av ferie eller annet fravær i kalenderen under."
                    >Se ferie/fravær</Txt>
                    <Tooltip id="patterns-vis-type" />
                    <ButtonGroup>
                        <Button
                            style={{paddingRight: "16px"}}
                            primary={visKinds.includes("vacation")}
                            onClick={() => toggleKind("vacation")}
                        >Ferie</Button>
                        <Button
                            style={{paddingLeft: "16px"}}
                            primary={visKinds.includes("not-working")}
                            onClick={() => toggleKind("not-working")}
                        >Fravær</Button>
                    </ButtonGroup>
                </Box>
                <Box style={{flex: "0 0 250px"}}>
                    <Txt style={{ "marginBottom": "1em" }}
                        data-for="patterns-day-segments"
                        data-tip="Knappene under aktiverer eller deaktiverer visning av vaktyper som har fravær i kalenderen under."
                    >Se vakter</Txt>
                    <Tooltip id="patterns-day-segments" />
                    <ButtonGroup>
                        <Button
                            style={{paddingRight: "18px"}}
                            primary={visDaySegments.includes("d")}
                            onClick={() => toggleDaySegment("d")}
                        >Dag</Button>
                        <Button
                            style={{padding: "4px 16px"}}
                            primary={visDaySegments.includes("a")}
                            onClick={() => toggleDaySegment("a")}
                        >Kveld</Button>
                        <Button
                            style={{padding: "4px 16px"}}
                            primary={visDaySegments.includes("n")}
                            onClick={() => toggleDaySegment("n")}
                        >Natt</Button>
                        <Button
                            style={{paddingLeft: "18px"}}
                            primary={visDaySegments.includes("l")}
                            onClick={() => toggleDaySegment("l")}
                        >Lang</Button>
                    </ButtonGroup>
                </Box>
                <Box style={{ flex: "0 0 95px"}}>
                    <Txt style={{ "marginBottom": "1.25em" }}
                        data-for="patterns-vis-year"
                        data-tip="Datoen av år hvis tilpasningen skal vises">Se år</Txt>
                    <Tooltip id="patterns-vis-year" />
                    <Flex justifyContent="left" alignItems="center">
                        <Button
                            primary
                            plain
                            style={{ "paddingRight": "1em" }}
                            onClick={() => setVisYear(visYear - 1)}>
                            <FontAwesomeIcon icon={faChevronLeft} />
                        </Button>
                        <h4 style={{ "margin": 0, "textAlign": "center" }}>
                            {visYear}
                        </h4>
                        <Button
                            primary
                            plain
                            style={{ "paddingLeft": "1em" }}
                            onClick={() => setVisYear(visYear + 1)}>
                            <FontAwesomeIcon icon={faChevronRight} />
                        </Button>
                    </Flex>
                </Box>
            </Flex>
            <CalendarHeatmap 
                data={rollupAndReduce(
                    filteredEmployees.map(e => 
                        getDaysFromDatePatterns(
                            dynomite,
                            e.blockedPatterns.concat(
                                e.vacationPatterns
                            ),
                            Math.max(...[
                                moment(`${visYear}-01-01`),
                                searchFilters.startDate ?
                                    moment(searchFilters.startDate)
                                    : moment(`${visYear}-01-01`),
                            ]),
                            Math.min(...[
                                moment(`${visYear}-12-31`),
                                searchFilters.endDate ?
                                    moment(searchFilters.endDate)
                                    : moment(`${visYear}-12-31`),
                            ]),
                            visKinds,
                            visDaySegments,
                            true
                        ).map(day => ({
                            Date: day,
                            Value: e.id
                        }))
                    ),
                    (day) => ({
                        Date: day[0].Date,
                        Value: day.reduce(
                            (acc, v) => acc.add(v.Value),
                            new Set()
                        ).size
                    })
                )}
                inMonths={
                    Array.from(Array(12).keys()).map(mId => `${visYear}-${mId + 1}`)
                }
                monthsLabels={Array.from(Array(12).keys()).reduce((a, v) => ({
                        ...a,
                        [`${visYear}-` + `0${v + 1}`.slice(-2)]: monthsShort[v]
                    }),
                    {}
                )}
                width={1350}
                height={250}
                margin={{top: 15, left: 0, bottom: 10, right: 0}}
                thresholdScale={{
                    domain: [
                        Math.round(employeesCount * 0.1),
                        Math.round(employeesCount * 0.2),
                        Math.round(employeesCount * 0.3),
                        Math.round(employeesCount * 0.4),
                        Math.round(employeesCount * 0.5)
                    ]
                }}
                colorScheme={fullSchemeYlOrRd}
                undefinedColor={"#ffffff"}
                legendLabel={"Antall ansatte som er fraværende/på ferie/permisjon"}
                hidden={false}
            />
            <Divider />
            <Box>
                <Accordion
                    hidePanelDivider={true}
                    keyFunc={(item) => item.id}
                    items={
                        filteredEmployees.map(empl => ({
                            key: `employee-row-${empl.id}`,
                            startOpen: false,
                            scrollIntoView: false,
                            label: <Flex
                                alignItems="center"
                                justifyContent="space-between"
                            >
                                <Box style={{ "flex": "1 0 10%"}}>
                                    <Txt style={{ "marginBottom": "1em" }}>{empl.name}</Txt>
                                </Box>
                                <Box style={{ "flex": "1 0 10%"}}>
                                    <Txt style={{ "marginBottom": "1em" }}>{empl.vacancyRate + "%"}</Txt>
                                </Box>
                                <Box style={{ "flex": "1 0 10%"}}>
                                    <Txt style={{ "marginBottom": "1em" }}>{empl.restPosition?.name}</Txt>
                                </Box>
                            </Flex>,
                            panel: <BlockedPatternsPickers
                                employee={empl}
                                setEmployees={setEmployees}
                                departmentId={departmentId}>
                            </BlockedPatternsPickers>
                        })
                    )}
                />
            </Box>
        </>
    )
};
