import React, {useContext, useEffect, useState} from "react";
import {Alert, Box, ButtonGroup, Button, Flex, Spinner, Txt} from "rendition";
import Accordion from "../accordion";
import {
    singleDaysDatePattern,
    getDaysFromDatePattern,
    rollupAndReduce,
    monthsShort
} from "../../lib/common";
import {dynoapeAPI} from "../../api/dynoape";
import {PatternCase} from "../../lib/pattern-case";
import dynomiteContext from "../dynomite";
import moment from "moment";
import DatePatternPicker from "./date-pattern-picker";
import Tooltip from "../../lib/tooltip";
import CalendarHeatmap  from '../vis-components/calendar-heatmap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';
import Icon from "../../lib/icon";

export default ({departmentId, employee, setEmployees}) => {

    const CATEGORIES = [
        "Ferie",
        "Fraværende",
        "Fravær med tillatt avvik"
    ]

    const [newInProgress, setNewInProgress] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [blockedPatterns, setBlockedPatterns] = useState(employee.blockedPatterns);
    const [vacationPatterns, setVacationPatterns] = useState(employee.vacationPatterns);
    const [dirties, setDirties] = useState([]);
    const placeholderIdPrefix = "placeholderId";
    const dynomite = useContext(dynomiteContext);
    const patternCase = PatternCase.BLOCKED_PATTERN;
    const [visYearOffset, setVisYearOffset] = useState(0);
    const visYearBase = new Date().getFullYear();
    const [visCategory, setVisCategory] = useState([...CATEGORIES]);

    useEffect(() => {
        setBlockedPatterns(employee.blockedPatterns);
        setVacationPatterns(employee.vacationPatterns);
    }, [employee])

    /**
     * Function for adding a dirty pattern to dirties and blockedPatterns for display.
     * Requested when an update has been made and the given pattern has not been saved
     * @param pattern
     */
    const setPattern = (pattern) => {
        setDirties([...dirties.filter(id => id !== pattern.id), pattern.id])
        setBlockedPatterns(blockedPatterns.map(p => p.id === pattern.id ? pattern : p));
    }

    /**
     * Function for validating a pattern using dynomite. Dynomite parsing
     * DatePattern can produce Error (with warnings), or DatePattern object
     * with warnings inside.
     * 
     * Both errors and warnings are collected and returned as an object
     * with 'errors' and 'warnings' properties, each of which is an object with
     * path as key and value as object with 'path' and 'message' properties.
     * 
     * @param pattern
     * @returns {{errors: {}, warnings: {}}}
     */
    const validatePattern = (pattern) => {
        let ret = {
            errors: {},
            warnings: {}
        };
        try {
            let dynomitePattern = dynomite.dynomite.parseDatePattern(JSON.stringify(pattern));
            if (dynomitePattern.warnings !== undefined) {
                ret.warnings = dynomitePattern.warnings.reduce((acc, warning) => {
                    if (Object.hasOwn(warning, 'tag') && warning.path) {
                        acc[warning.path] = dynomite.dynomite.formatError(warning)
                    }
                    return acc;
                }, {});
            }
        } catch (e) {
            return e.payload.reduce((acc, err) => {
                if (Object.hasOwn(err, 'tag') && err.path) {
                    if (!err.tag.startsWith('Warning::')) {
                        acc.errors[err.path] = dynomite.dynomite.formatError(err)
                    } else {
                        acc.warnings[err.path] = dynomite.dynomite.formatError(err)
                    }
                }
                return acc;
            }, ret);
        }
        return ret;
    }

    /**
     * Function for either posting or putting a blocked pattern.
     * Will post if id contains 'placeholderIdPrefix', and put if not
     * @param patt
     * @returns {Promise<void>}
     */
    const save = async (patt) => {
        const valObj = validatePattern(patt);
        if (Object.keys(valObj.errors).length > 0) {
            return;
        }

        setIsSaving(true)
        let newPattern = JSON.parse(JSON.stringify(patt));
        ['id', 'registered', 'updated'].forEach(field => delete newPattern[field])
        let blocked = blockedPatterns;
        if(patt.id.includes(placeholderIdPrefix)) {
            const resp = await dynoapeAPI.post(`/api/v1/department/${departmentId}/employee/${employee.id}/pattern?type=${patternCase}`, newPattern);
            if(resp) blocked = blockedPatterns.map(p => p.id.includes(placeholderIdPrefix) ? resp : p);
        } else {
            const resp = await dynoapeAPI.put(`/api/v1/department/${departmentId}/employee/${employee.id}/pattern/${patt.id}?type=${patternCase}`, newPattern);
            if(resp) blocked = blockedPatterns.map(p => p.id === resp.id ? resp : p);
        }
        setBlockedPatterns(blocked);
        employee.blockedPatterns = blocked;
        setEmployees((prev) => ([
            ...prev.filter(e => e.id !== employee.id),
            employee
        ].sort(function (a, b) {
            return a.priority === b.priority
                ? new Date(a.registered) - new Date(b.registered)
                : a.priority - b.priority
        })))
        setNewInProgress(false)
        setIsSaving(false);
        setDirties(dirties.filter(id => id !== patt.id));
    }

    /**
     * Method for deleting a pattern
     * @param patt
     * @param type
     * @returns {Promise<void>}
     */
    const del = async (patt, type=patt) => {
        setIsSaving(true)
        if(patt.id.includes(placeholderIdPrefix)) {
            setNewInProgress(false)
            setBlockedPatterns(blockedPatterns.filter(p => !p.id.includes(placeholderIdPrefix)));
        } else {
            await dynoapeAPI.delete(`/api/v1/department/${departmentId}/employee/${employee.id}/pattern/${patt.id}?type=${type || patternCase}`)
            if(type === PatternCase.VACATION) setVacationPatterns(vacationPatterns.filter(p => p.id !== patt.id));
            else setBlockedPatterns(blockedPatterns.filter(p => p.id !== patt.id));
        }
        setIsSaving(false);
    }

    const accordionConfig = (pattern) => {
        return {
            patternCase: patternCase,
            startOpen: pattern.id.includes(placeholderIdPrefix),
            scrollIntoView: pattern.id.includes(placeholderIdPrefix),
            pattern: pattern,
            setPattern: setPattern,
            validation: validatePattern(pattern),
            readOnly: false,
            save: save,
            del: del,
            isSaving: isSaving,
            dirties: dirties
        }
    }

    /**
     * Method for sorting a list of pattern by update time
     * @param patterns
     * @returns {*}
     */
    const sortByUpdated = (patterns) => {
        return patterns?.sort(function (a, b) {
            if(!a.updated) {
                return -1
            } else if(!b.updated) {
                return 1
            } else {
                return new Date(b.updated) - new Date(a.updated);
            }
        });
    }

    

    /**
     * Method to return category name based on DatePattern data
     * 
     * Function can return undefined if kind is outside allowed set.
     * @param pattern - instance of DatePattern 
     * @returns {string|undefined} - name of category
     */
    const categoryFromPattern = (pattern) => {
        if (pattern.kind === "vacation")
            return CATEGORIES[0];
        else if (pattern.kind === "notWorking") {
            if (pattern.optimize)
                return CATEGORIES[2];
            else
                return CATEGORIES[1]
        }
        return undefined
    }


    return(
        <>
            <Flex justifyContent="start" alignItems="center" style={{gap: "1.5em", marginBottom: "1em"}}>
                <ButtonGroup>
                    {/* VACATION */}
                    <Button
                        style={{
                            padding: "4px 25px",
                            backgroundColor: visCategory.includes(CATEGORIES[0]) ? '#0571b0': '#fff',
                            color: visCategory.includes(CATEGORIES[0]) ? '#fff': '#333'
                        }}
                        primary={visCategory.includes(CATEGORIES[0])}
                        onClick={() => {
                            visCategory.includes(CATEGORIES[0]) ?
                            setVisCategory(visCategory.filter(c => c !== CATEGORIES[0]))
                            : setVisCategory([...visCategory, CATEGORIES[0]])
                        }}
                        
                    >{CATEGORIES[0]}</Button>

                    {/* BLOCKED DAYS */}
                    <Button
                        style={{
                            padding: "4px 25px",
                            backgroundColor: visCategory.includes(CATEGORIES[1]) ? '#ca0020': '#fff',
                            color: visCategory.includes(CATEGORIES[1]) ? '#fff': '#333'
                        }}
                        primary={visCategory.includes(CATEGORIES[1])}
                        onClick={() => {
                            visCategory.includes(CATEGORIES[1]) ?
                            setVisCategory(visCategory.filter(c => c !== CATEGORIES[1]))
                            : setVisCategory([...visCategory, CATEGORIES[1]])
                        }}
                    >{CATEGORIES[1]}</Button>

                    {/* OPTIMIZED BLOCKED DAYS */}
                    <Button
                        style={{
                            padding: "4px 25px",
                            backgroundColor: visCategory.includes(CATEGORIES[2]) ? '#f4a582': '#fff',
                            color: visCategory.includes(CATEGORIES[2]) ? '#fff': '#333'
                        }}
                        primary={visCategory.includes(CATEGORIES[2])}
                        onClick={() => {
                            visCategory.includes(CATEGORIES[2]) ?
                            setVisCategory(visCategory.filter(c => c !== CATEGORIES[2]))
                            : setVisCategory([...visCategory, CATEGORIES[2]])
                        }}
                    >{CATEGORIES[2]}</Button>

                </ButtonGroup>
                <Flex justifyContent="left" alignItems="center">
                    <Button
                        primary
                        plain
                        style={{ paddingRight: "1em" }}
                        onClick={() => setVisYearOffset(visYearOffset - 1)}>
                        <FontAwesomeIcon icon={faChevronLeft} />
                    </Button>
                    <h4 style={{ "margin": 0, "textAlign": "center" }}>
                        {visYearBase + visYearOffset}
                    </h4>
                    <Button
                        primary
                        plain
                        style={{ paddingLeft: "1em" }}
                        onClick={() => setVisYearOffset(visYearOffset + 1)}>
                        <FontAwesomeIcon icon={faChevronRight} />
                    </Button>
                </Flex>
                <Box style={{flexGrow: 1}}></Box>
                <Box 
                    style={{
                        backgroundColor: "#306970",
                        padding: '4px',
                        width: '40px',
                        height: '40px',
                        borderRadius: '50%',
                        marginRight: "20px",
                    }}
                    data-for="employee-block-visualization"
                    data-tip='Den visuelle oversikten gir deg en tydelig visning av alle planlagte fraværsdager for en ansatt i løpet av året. Du kan enkelt se om fraværet gjelder hele dagen eller bare deler av den, og om fraværet har "Tillat avvik" aktivert. Ferieinformasjon hentes fra Ferieplanleggeren. <br /><br /> Du kan filtrere visningen ved å velge de ulike fraværstypene.'
                >
                    <Icon name="help" />
                </Box>
                <Tooltip id="employee-block-visualization" />
            </Flex>
            <CalendarHeatmap 
                data={rollupAndReduce(
                    blockedPatterns.concat(vacationPatterns)
                        .filter(pattern => visCategory.includes(categoryFromPattern(pattern)))
                        .map(
                            pattern => getDaysFromDatePattern(
                                dynomite,
                                pattern,
                                `${visYearBase + visYearOffset}-01-01`,
                                `${visYearBase + visYearOffset}-12-31`,
                                false
                            ).map(day => ({
                                Date: day,
                                Category: categoryFromPattern(pattern),
                                DaySegments: pattern.daySegments
                            }))
                        ),
                    (day) => {
                        // Collect all categories present in the current day
                        let DSinCategory = day.reduce(
                            (acc, v) => {
                                acc[v.Category] = acc[v.Category].union(
                                    new Set(v.DaySegments)
                                );
                                return acc;
                            },
                            CATEGORIES.reduce(
                                (acc, v) => ({...acc, [v]: new Set()}),
                                {}
                            )
                        );
                        // Go through categories and iteratively remove day
                        // segments compositions from the highest ordered
                        // category to the lowest.
                        let filteredCategories = CATEGORIES.reduce(
                            (acc, cat) => {
                                if (DSinCategory[cat].difference(acc.collected).size > 0) {
                                    acc.result.push(cat);
                                }
                                acc.collected = acc.collected.union(DSinCategory[cat]);
                                return acc;
                            },
                            {
                                collected: new Set(),
                                result: []
                            }
                        );
                        // Return the Date and list of categories with some day
                        // segments present. Put undefined if some day segments
                        // were not covered by any category.
                        return ({
                            Date: day[0].Date,
                            Value: [
                                ...filteredCategories.result,
                                ... filteredCategories.collected.size !== 4 ? [undefined] : []
                            ]
                        })
                    }
                )}
                inMonths={
                    Array.from(Array(12).keys()).map(mId => 
                        `${visYearBase + visYearOffset}-${mId + 1}`
                    )
                }
                monthsLabels={Array.from(Array(12).keys()).reduce((a, v) => ({
                        ...a,
                        [`${visYearBase + visYearOffset}-` + `0${v + 1}`.slice(-2)]: monthsShort[v]
                    }),
                    {}
                )}
                width={1300}
                height={220}
                ordinalScale={{domain: CATEGORIES}}
                colorScheme={[[], [], ['#0571b0', '#ca0020', '#f4a582']]}
                undefinedColor = "#ffffff"
                legendLabel = ''
                showShapeLegend
                shapeLegendLabel = ''
                shapeLegendLabels={['Hele dagen', 'Deler av dagen']}
                shapeLegendColors = {['#ca0020', '#ca0020']}
                hidden={false}
            />
            <Button disabled={newInProgress} style={{marginBottom: "20px"}} onClick={() => {
                setNewInProgress(true);
                setBlockedPatterns([
                    ...blockedPatterns,
                    {
                        ...singleDaysDatePattern(
                            "",
                            [moment().startOf('isoWeek').format('YYYY-MM-DD')]
                        ),
                        id: placeholderIdPrefix,
                        daySegments: [],
                        includeDays: []
                    }
                ]);
            }}
            >
                Legg til ansattilpasninger
            </Button>
            {(blockedPatterns || []).length > 0 && <Accordion
                hidePanelDivider={true}
                keyFunc={(item) => item.id}
                items={sortByUpdated(blockedPatterns).map(p => PatternRow({...accordionConfig(p), patternCase: PatternCase.BLOCKED_PATTERN}))}
            />}
            {(vacationPatterns || []).length > 0 && <Accordion
                hidePanelDivider={true}
                keyFunc={(item) => item.id}
                items={vacationPatterns.map(p => PatternRow({...accordionConfig(p), readOnly: true, patternCase: PatternCase.VACATION}))}
            />}
        </>
    )
}

/**
 * Component for rendering an element in the accordion, where each element represents a blocked pattern.
 * @param patternCase
 * @param startOpen
 * @param scrollIntoView
 * @param pattern
 * @param setPattern
 * @param validation - Result of validatePattern(pattern) call.
 * @param readOnly
 * @param save
 * @param del
 * @param isSaving
 * @param dirties
 * @returns {{scrollIntoView, label: JSX.Element, panel: JSX.Element, key, startOpen}}
 * @constructor
 */
const PatternRow = ({patternCase, startOpen, scrollIntoView, pattern, setPattern, validation, readOnly, save, del, isSaving, dirties }) => {

    return {
        startOpen: startOpen,
        key: pattern.id,
        label: <Flex alignItems="center" justifyContent="space-between">
            <Box style={{ "flex": "1 0 10%"}}><Txt style={{ "marginBottom": "1em" }}>{pattern.summary}</Txt></Box>
            <Flex style={{ padding: "1em", marginLeft: "auto", gap: "1em", alignItems: "center" }}>
                {readOnly && <Alert plaintext info style={{ whiteSpace: "nowrap" }}>Kan kun endres i <i>{PatternCase.translate(patternCase)}</i></Alert>}
                {(pattern.id.includes("placeholder") || dirties.includes(pattern.id)) && <Alert plaintext warning style={{ whiteSpace: "nowrap" }}>Ikke lagret</Alert>}
                {pattern.disabled && <Alert plaintext danger style={{ whiteSpace: "nowrap" }}>Ikke aktiv</Alert>}
                {!pattern.disabled && <Alert plaintext success style={{ whiteSpace: "nowrap" }}>Aktiv</Alert>}
            </Flex>
        </Flex>,
        scrollIntoView: scrollIntoView,
        panel: <>
            <Box style={{
                "margin": "0em 1em 1em 1em",
                "padding": "2em",
                "borderRadius": "10px",
                "boxShadow": "inset 0 1px 3px rgba(0,0,0,0.10), inset 0 1px 4px rgba(0,0,0,0.16)",
                "background": readOnly ? "rgb(245,245,245)": ""
            }}>
                <DatePatternPicker
                    pattern={pattern}
                    setPattern={setPattern}
                    validation={validation}
                    readOnly={readOnly}
                />
            </Box>
                    <Flex style={{
                        padding: "2em",
                        gap: "1em",
                        alignItems: "center"
                    }}>
                        {pattern.disabled && !readOnly && <Button
                            style={{ "marginLeft": "15px", "marginRight": "15px" }}
                            underline
                            success
                            disabled={isSaving}
                            onClick={() => setPattern({...pattern, disabled: false})}>Aktiver</Button>}
                        {!pattern.disabled && !readOnly && <Button
                            style={{ "marginLeft": "15px", "marginRight": "15px" }}
                            underline
                            warning
                            disabled={isSaving}
                            onClick={() => setPattern({...pattern, disabled: true})}>Deaktiver</Button>}
                        <Button
                            style={{ "marginLeft": "15px", "marginRight": "15px" }}
                            underline
                            danger
                            disabled={isSaving}
                            onClick={() => del(pattern, patternCase)}>Slett</Button>
                        <Button
                            style={{ "marginLeft": "15px", "marginRight": "15px" }}
                            primary
                            underline
                            disabled={isSaving || readOnly || Object.keys(validation.errors).length > 0}
                            onClick={() => save(pattern)}>Lagre</Button>
                        {(Object.keys(validation.errors).length + Object.keys(validation.warnings).length) > 0 &&
                            <Flex flexDirection={"column"}>
                                {Object.values(validation.errors).map((err, idx) => (
                                    <span key={`pattern-error-${idx}`}>
                                        <Alert plaintext danger style={{ whiteSpace: "nowrap" }}>{err.replace(/ *\([^)]*\) */g, " ")}</Alert>
                                    </span>
                                ))}
                                {Object.values(validation.warnings).map((warn, idx) => (
                                    <span key={`pattern-warning-${idx}`}>
                                        <Alert plaintext warning style={{ whiteSpace: "nowrap" }}>{warn.replace(/ *\([^)]*\) */g, " ")}</Alert>
                                    </span>
                                ))}
                            </Flex>
                        }
                        <Spinner show={isSaving} />
                    </Flex>
        </>
    }
}